import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../Constants";
export default function NavBar() {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isPopupVisible, setisPopupVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const [password, setPassword] = useState({
        current_password: "",
        new_password: "",
        confirm_password: "",
      });
      const authToken = localStorage.getItem("token");
  const validatePasswords = () => {
    const newErrors = {};

    // Current password validation
    if (!password.current_password.trim()) {
      newErrors.current_password = "Current password is required";
    }

    // New password validation
    if (!password.new_password.trim()) {
      newErrors.new_password = "New password is required";
    } else if (password.new_password.length < 8) {
      newErrors.new_password =
        "New password must be at least 8 characters long";
    }
    // Confirm password validation
    if (!password.confirm_password.trim()) {
      newErrors.confirm_password = "Confirm password is required";
    } else if (password.new_password !== password.confirm_password) {
      newErrors.confirm_password = "Passwords do not match";
    }

    setErrors(newErrors);

    // Return whether the form is valid or not
    return Object.keys(newErrors).length === 0;
  };

  const passwordChange = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };
  const changePassword = async (event) => {
    event.preventDefault();
    if (validatePasswords()) {
      try {
        const response = await axios.post(
          `${API_URL}/updatePassword`,
          password,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setisPopupVisible(!isPopupVisible);
        toast.success(response.data.success);
        setPassword(password);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data.error) {
          setErrors(error.response.data.error);
        } else {
          console.error("Error:", error);
        }
      }
    }
  };
    useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const showDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
      const showPopup = () => {
        setisPopupVisible(!isPopupVisible);
      };
      const logout = () => {
        localStorage.removeItem("token");
        navigate('/login');
        // setIsLoggedin(false);
    };
  return (
    <>
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
          <a
            className="nav-item nav-link px-0 me-xl-4"
                      >
            <i className="ti ti-menu-2 ti-sm"></i>
          </a>
        </div>
        <ToastContainer />

        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          {/* <!-- Search --> */}
          {/* <div className="navbar-nav align-items-center">
            <div className="nav-item navbar-search-wrapper mb-0">
              <a
                className="nav-item nav-link search-toggler d-flex align-items-center px-0"
                
              >
                <i className="ti ti-search ti-md me-2"></i>
                <span className="d-none d-md-inline-block text-muted">
                  Search (Ctrl+/)
                </span>
              </a>
            </div>
          </div> */}
          {/* <!-- /Search --> */}

          <ul className="navbar-nav flex-row align-items-center ms-auto">

            {/* <!-- User --> */}
            <li
            ref={dropdownRef}
              className="nav-item navbar-dropdown dropdown-user dropdown"
              onClick={showDropdown}
            >
              <a
                className={`nav-link dropdown-toggle hide-arrow ${
                  isDropdownVisible ? "show" : ""
                }`}
                
                data-bs-toggle="dropdown"
                aria-expanded={isDropdownVisible ? "true" : "false"}
              >
                <div className="avatar avatar-online">
                  <img
                    src="../../assets/img/avatars/1.png"
                    
                    className="h-auto rounded-circle"
                  />
                </div>
              </a>
              <ul
                className={`dropdown-menu dropdown-menu-end ${
                  isDropdownVisible ? "show" : ""
                }`}
                data-bs-popper="static"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                  >
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            src="../../assets/img/avatars/1.png"
                            
                            className="h-auto rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-medium d-block">Admin</span>
                        <small className="text-muted">Admin</small>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" to="/profile">
                    <i className="ti ti-user-check me-2 ti-sm"></i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="admin/company-setting"
                  >
                    <i className="ti ti-settings me-2 ti-sm"></i>
                    <span className="align-middle">Settings</span>
                  </Link>
                </li>
                <li onClick={showPopup}>
                  <a className="dropdown-item" href="#">
                    <span className="d-flex align-items-center align-middle">
                      <i className="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
                      <span className="flex-grow-1 align-middle">
                        Change password
                      </span>
                    </span>
                  </a>
                </li>
               
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClickCapture={logout}
                  >
                    <i className="ti ti-logout me-2 ti-sm"></i>
                    <span className="align-middle">Log Out</span>
                  </a>
                </li>
              </ul>
            </li>
            {/* <!--/ User --> */}
          </ul>
        </div>

        {/* <!-- Search Small Screens --> */}
        <div className="navbar-search-wrapper search-input-wrapper  d-none">
          <input
            type="text"
            className="form-control search-input container-xxl border-0"
            placeholder="Search..."
            aria-label="Search..."
          />
          <i className="ti ti-x ti-sm search-toggler cursor-pointer"></i>
        </div>
      </nav>
            {/* <!-- Add New Credit Card Modal --> */}
            <div
        className={`modal fade ${isPopupVisible ? "show" : ""}`}
        id="addNewCCModal"
        tabIndex="-1"
        aria-hidden="true"
        style={{ display: isPopupVisible ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={showPopup}
              ></button>
              <div className="text-center mb-4">
                <h3 className="mb-2">Change Password</h3>
                {/* <p className="text-muted">Add new card to complete payment</p> */}
              </div>
              <form
                id="addNewCCForm"
                className="row g-3"
                method="post"
                onSubmit={changePassword}
              >
                <div className="col-12">
                  <label className="form-label w-100" htmlFor="current_password">
                    Current Password
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      id="current_password"
                      onChange={passwordChange}
                      value={password.current_password}
                      name="current_password"
                      className="form-control credit-card-mask"
                      type="text"
                      placeholder="Enter current password"
                      aria-describedby="modalAddCard2"
                    />
                    <span
                      className="input-group-text cursor-pointer p-1"
                      id="modalAddCard2"
                    >
                      <span className="card-type"></span>
                    </span>
                  </div>
                  {errors.current_password && (
                    <span style={{ color: "red" }}>
                      {errors.current_password}
                    </span>
                  )}
                </div>
                <div className="col-12">
                  <label className="form-label w-100" htmlFor="new_password">
                    New Password
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      id="new_password"
                      name="new_password"
                      onChange={passwordChange}
                      value={password.new_password}
                      className="form-control credit-card-mask"
                      type="text"
                      placeholder="Enter new password"
                      aria-describedby="modalAddCard2"
                    />

                    <span
                      className="input-group-text cursor-pointer p-1"
                      id="modalAddCard2"
                    >
                      <span className="card-type"></span>
                    </span>
                  </div>
                  {errors.new_password && (
                    <span style={{ color: "red" }}>{errors.new_password}</span>
                  )}
                </div>
                <div className="col-12">
                  <label className="form-label w-100" htmlFor="confirm_password">
                    Confirm Password
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      id="confirm_password"
                      name="confirm_password"
                      onChange={passwordChange}
                      value={password.confirm_password}
                      className="form-control credit-card-mask"
                      type="text"
                      placeholder="Enter confirm password"
                      aria-describedby="modalAddCard2"
                    />
                    <span
                      className="input-group-text cursor-pointer p-1"
                      id="modalAddCard2"
                    >
                      <span className="card-type"></span>
                    </span>
                  </div>
                  {errors.confirm_password && (
                    <span style={{ color: "red" }}>
                      {errors.confirm_password}
                    </span>
                  )}
                </div>
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary btn-reset"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={showPopup}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!--/ Add New Credit Card Modal --> */}
    </>
  );
}
