import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { API_URL } from "./Admin/Constants";
import { BACKEND_URL } from "./Admin/Constantsurl";

export default function CompanyCompetitionDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const authToken = localStorage.getItem("token");
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [errors, setErrors] = useState({});
  const [current_page, setCurrent_page] = useState("1");
  const [links, setLinks] = useState([]);
  const [checkedStates, setCheckedStates] = useState({});
  const [complist, setComplist] = useState([]);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  const fetchData = async (page) => {
    try {
      const response = await axios.get(
        `${API_URL}/companyCompetitionDetails?page=${current_page ? current_page : page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setComplist(response.data.data);
      setCurrent_page(response.data.current_page);
      setLinks(response.data.links);
      const initialCheckedStates = response.data.data.reduce((acc, item) => {
        acc[item.id] = item.status === 1;
        return acc;
      }, {});
      setCheckedStates(initialCheckedStates);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData(current_page);
  }, [current_page]);

  const handleCheck = async (id) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    try {
      const response = await axios.post(
        `${API_URL}/companyCompetitionDetails/changeStatus/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error updating status", error);
    }
  };

  const showPopup = () => {
    navigate('/admin/add-companyCompetition-details');
  };

  const toggleDropdown = (comp_id) => {
    setVisibleDropdown(visibleDropdown === comp_id ? null : comp_id);
  };

  const editCompanyCompetition = (id) => {
    navigate(`/admin/editCompanyCompetitionDetails/${id}`)
  };

  const deleteCompetition = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${API_URL}/deleteCompanyCompetitionDetails/${id}`,
            { id: id },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          )
          .then((response) => {
            fetchData();
            Swal.fire(
              "Deleted!",
              "Data has been deleted.",
              "success"
            );
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              setErrors(error.response.data.error);
            } else {
              console.error("Error:", error);
            }
          });
      }
    });
  };

  const pagination = (url) => {
    if (url) {
      const page = new URL(url).searchParams.get('page');
      setCurrent_page(Number(page));
    }
  }

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <div className="layout-page">
            <div className="content-wrapper" style={{ display: "inline-block" }}>
              <ToastContainer />
              <div className="col-12 px-4 mt-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="container-xxl flex-grow-1 container-p-y">
                      <h4>
                        <span className="fw-light text-dark">
                          Company Competition Detail List
                        </span>
                      </h4>
                      <button
                        type="button"
                        className="btn btn-primary rounded-pill"
                        onClick={showPopup}
                      >
                        <i className="ti ti-plus ti-xs"></i> Add Company Competition Detail
                      </button>
                    </div>
                    <div className="row">
                      <div className="card">
                        <div className="table-responsive text-nowrap">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Sr. no</th>
                                <th>Title</th>
                                <th>Country</th>
                                <th>Education</th>
                                <th>Category</th>
                                <th>Link</th>
                                <th>Description</th>
                                <th>Image</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                              {complist.map((item, index) => (
                                <tr key={index}>
                                  <td>{(current_page * 10) + index + 1 - 10}</td>
                                  <td>
                                    <span className="fw-medium">{truncateString(item.title, 30)}</span>
                                  </td>
                                  <td>
                                    <span className="fw-medium">
                                      {item.regions ? JSON.parse(item.regions).join(", ") : ""}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fw-medium">
                                      {item.education ? JSON.parse(item.education).join(", ") : ""}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fw-medium">
                                      {item.category ? JSON.parse(item.category).join(", ") : ""}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fw-medium">
                                      <a href={item.link} target="_blank" rel="noopener noreferrer">
                                        {item.competition_url}
                                      </a>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fw-medium">{truncateString(stripHtmlTags(item.description), 30)}</span>
                                  </td>
                                  <td>
                                    <img src={`${BACKEND_URL}/storage/images/company/${item.competition_image}`} height="100px" width="100px" alt="Competition" />
                                  </td>
                                  <td>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className="switch-input"
                                        checked={checkedStates[item.id] || false}
                                        onChange={() => handleCheck(item.id)}
                                        name="status"
                                      />
                                      <span className="switch-toggle-slider">
                                        <span className="switch-on"></span>
                                        <span className="switch-off"></span>
                                      </span>
                                      <span className="switch-label">
                                        {checkedStates[item.id] ? "Active" : "Inactive"}
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <a onClick={() => editCompanyCompetition(item.id)} style={{ marginRight: 5 }}>
                                        <i className="ti ti-pencil" style={{ color: "white", borderRadius: "7.5px", background: "darkkhaki" }}></i>
                                      </a>
                                      <a onClick={() => deleteCompetition(item.id)}>
                                        <i className="ti ti-trash" style={{ color: "white", borderRadius: "7.5px", background: "#c21027" }}></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div style={{ marginTop: "17px", textAlign: "center", justifyContent: "center", display: "flex" }}>
                          <nav aria-label="Page navigation">
                            <ul className="pagination">
                              {links.map((item, index) => {
                                let label = index === 0 ? "previous" : index === links.length - 1 ? "next" : index;
                                return (
                                  <li key={index} className={`page-item ${item.active ? 'active' : ''}`}>
                                    <a className="page-link" onClick={() => pagination(item.url)}>
                                      {label}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
