import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./Constants";

export default function AddStory() {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);

    try {
      await axios.post(
        `${API_URL}/addStory`, // Adjust the endpoint as needed
        { heading: title, description },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Story added successfully");
      // Delay navigation to allow the toast to be displayed
      setTimeout(() => {
        navigate("/admin/story-list"); // Redirect to the story list or any other page
      }, 1000); // Adjust the delay as needed (1000ms = 1 second)
    } catch (error) {
      console.error("Error adding story", error);
      toast.error("Failed to add story");
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="container">
        <h2>Add Story</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                className={`form-control ${errors.heading ? 'is-invalid' : ''}`}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {errors.heading && <div className="invalid-feedback">{errors.heading}</div>}
            </div>
            <div className="form-group mt-3">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                rows="5"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              {errors.description && <div className="invalid-feedback">{errors.description}</div>}
            </div>
            <button type="submit" className="btn btn-primary mt-3">
              Add Story
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
