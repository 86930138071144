import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "./Admin/Elements/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./Admin/Constants";
import { BACKEND_URL } from "./Admin/Constantsurl";

function Profile() {
  const initialState = {
    Username: "",
    email: "",
    phoneNumber: "",
    address: "",
    state: "",
    zipCode: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [file, setFile] = useState("../../assets/img/avatars/14.png");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonText, setButtonText] = useState("Upload new photo");

  const authToken = localStorage.getItem("token");

    useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/index`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setFormData((prevData) => ({
          ...prevData,
          Username: response.data.user.username,
          email: response.data.user.email,
          phoneNumber: response.data.user.phone_number,
          address: response.data.user.address,
          state: response.data.user.state,
          zipCode: response.data.user.zipCode,
        }));
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateFormData = () => {
    let errors = {};

    if (!formData.Username?.trim()) {
      errors.Username = "Username is required";
    }

    if (!formData.phoneNumber?.trim()) {
      errors.phoneNumber = "Phone number is required";
    } 
    // else if (!isValidPhoneNumber(formData.phoneNumber)) {
    //   errors.phoneNumber = "Invalid phone number format";
    // }

    if (!formData.address?.trim()) {
      errors.address = "Address is required";
    }

    if (!formData.state?.trim()) {
      errors.state = "State is required";
    }

    if (!formData.zipCode?.trim()) {
      errors.zipCode = "Zip code is required";
    }
    // else if (!isValidZipCode(formData.zipCode)) {
    //   errors.zipCode = "Invalid zip code format";
    // }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // const isValidPhoneNumber = (phoneNumber) => {
  //   return /^\d{10}$/.test(phoneNumber);
  // };

  // const isValidZipCode = (zipCode) => {
  //   return /^\d{5}$/.test(zipCode);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormData()) {
      try {
        const response = await axios.post(
          `${API_URL}/updateProfile`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setFormData(formData);
        toast.success(response.data.message);
      } catch (error) {
        if (error.response && error.response.data.errors  ) {
          setErrors(error.response.data.errors);
        } else {
          console.error("Error:", error);
        }
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/showImage`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        // console.log(localStorage);
        const profilePic = response.data.profile_pic;
        setFile(
          profilePic
            ? `${BACKEND_URL}/storage/images/${profilePic}`
            : "../../assets/img/avatars/14.png"
        );
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function imageUpload(e) {
    const file = e.target.fies[0];
    setFile(URL.createObjectURL(file));

    setButtonText("Uploading...");
    const formData = new FormData();
    formData.append("file", file);

    try {
      const authToken = localStorage.getItem("token");
      axios
        .post(`${API_URL}/uploadImage`, formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setTimeout(() => {
            setButtonText("Uploaded");
          }, 2000);
          toast.success(response.data.message);
          setErrors({});
          setTimeout(() => {
            setButtonText("Upload new photo");
          }, 4000);
        })
        .catch((error) => {
          if (error.response && error.response.data.error) {
            const errorMessage = error.response.data.error.file[0];
            errors.file = errorMessage;
            setErrors(errors);
            setTimeout(() => {
              setButtonText("Upload new photo");
            }, 1000);
          } else {
            console.error("Error:", error);
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar  ">
          <div className="layout-container">
            {/* <!-- Menu --> */}
            {/* <!-- / Menu --> */}

            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Navbar --> */}

              {/* <!-- / Navbar --> */}

              {/* <!-- Content wrapper --> */}
              <div className="content-wrapper">
                {/* <!-- Content --> */}
                <ToastContainer />
                <div className="container-xxl flex-grow-1 container-p-y">
                  {successMessage && (
                    <p style={{ color: "green" }}>{successMessage}</p>
                  )}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card mb-4">
                        {/* <!-- Account --> */}

                        <div className="card-body">
                          <h4 className="">
                            <span className=" fw-light text-dark">
                              Profile Details
                            </span>
                            <h6 className="text-muted">Fill Profile Details</h6>
                          </h4>
                          <div className="d-flex align-items-start align-items-sm-center gap-4">
                            <img
                              src={file}
                              alt="user-avatar"
                              className="d-block w-px-100 h-px-100 rounded"
                              id="uploadedAvatar"
                            />
                            <div className="button-wrapper">
                              <label
                                htmlFor="upload"
                                className="btn btn-primary me-2 mb-3"
                                tabIndex="0"
                              >
                                <span className="d-none d-sm-block">
                                  {buttonText}
                                </span>

                                <i className="ti ti-upload d-block d-sm-none"></i>
                                <input
                                  onChange={imageUpload}
                                  type="file"
                                  id="upload"
                                  className="account-file-input"
                                  hidden
                                  accept="image/png, image/jpeg"
                                />
                              </label>
                              {errors.file && (
                                  <span className="error text-danger">{errors.file}</span>
                                )}
                              {/* <button
                                type="button"
                                className="btn btn-label-secondary account-image-reset mb-3"
                              >
                                <i className="ti ti-refresh-dot d-block d-sm-none"></i>
                                <span className="d-none d-sm-block">Reset</span>
                              </button> */}

                              <div className="text-muted">
                                Allowed JPG, GIF or PNG. Max size of 2mb
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-0" />
                        <div className="card-body">
                          <form
                            id="formAccountSettings"
                            method="POST"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  htmlFor="Username"
                                  className="form-label"
                                >
                                  Username
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="Username"
                                  name="Username"
                                  value={formData.Username}
                                  onChange={handleChange}
                                />
                                {errors.Username && (
                                  <span className="error text-danger">{errors.Username}</span>
                                )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="email" className="form-label">
                                  E-mail
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  placeholder="john.doe@example.com"
                                  disabled
                                  readOnly
                                />
                                  {errors.email && (
                                    <span className="error text-danger">
                                      {errors.email}
                                    </span>
                                  )}
                              </div>{" "}
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="phoneNumber"
                                >
                                  Phone Number
                                </label>
                                <div className="input-group input-group-merge">
                                  {/* <span className="input-group-text">US (+1)</span> */}
                                  <input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="202 555 0111"
                                  />
                                
                                </div>
                                {errors.phoneNumber && (
                                    <span className="error text-danger">
                                      {errors.phoneNumber}
                                    </span>
                                  )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="address" className="form-label">
                                  Address
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  name="address"
                                  value={formData.address}
                                  onChange={handleChange}
                                  placeholder="Address"
                                />
                                {errors.address && (
                                  <span className="error text-danger">
                                    {errors.address}
                                  </span>
                                )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="state" className="form-label">
                                  State
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="state"
                                  name="state"
                                  value={formData.state}
                                  onChange={handleChange}
                                  placeholder="California"
                                />
                                {errors.state && <span className="error text-danger" >{errors.state}</span>}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="zipCode" className="form-label">
                                  Zip Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="zipCode"
                                  name="zipCode"
                                  placeholder="231465"
                                  maxLength="8"
                                  value={formData.zipCode}
                                  onChange={handleChange}
                                />
                                {errors.zipCode && (
                                  <span className="error text-danger">{errors.zipCode}</span>
                                )}
                              </div>
                            </div>
                            <div className="mt-2">
                              <Button
                                text="update"
                                className="btn btn-primary me-2"
                              />
                            </div>
                          </form>
                        </div>
                        {/* <!-- /Account --> */}
                      </div>
                      {/* <div className="card">
                        <h5 className="card-header">Delete Account</h5>
                        <div className="card-body">
                          <div className="mb-3 col-12 mb-0">
                            <div className="alert alert-warning">
                              <h5 className="alert-heading mb-1">
                                Are you sure you want to delete your account?
                              </h5>
                              <p className="mb-0">
                                Once you delete your account, there is no going
                                back. Please be certain.
                              </p>
                            </div>
                          </div>
                          <form id="formAccountDeactivation">
                            <div className="form-check mb-4">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="accountActivation"
                                id="accountActivation"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="accountActivation"
                              >
                                I confirm my account deactivation
                              </label>
                            </div>
                            <button
                              type="submit"
                              className="btn btn-danger deactivate-account"
                            >
                              Deactivate Account
                            </button>
                          </form>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}

                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}

                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page -->/ */}
          </div>

          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>

          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}

        {/* <div className="buy-now">
          <a
            href="https://1.envato.market/vuexy_admin"
            target="_blank"
            className="btn btn-danger btn-buy-now"
          >
            Buy Now
          </a>
        </div> */}
      </div>
    </>
  );
}

export default Profile;
