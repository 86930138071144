import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { API_URL } from "./Constants";
import { BACKEND_URL } from "./Constantsurl";

export default function Events() {
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [quillInput, setQuillInput] = useState({ description: "" });
  const authToken = localStorage.getItem("token");
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [errors, setErrors] = useState({});
  const [current_page, setCurrent_page] = useState("1");
  const [links, setLinnks] = useState([]);
  const [checkedStates, setCheckedStates] = useState({});
  const [complist, setComplist] = useState({});

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  const fetchData = async (page) => {
    try {
      const response = await axios.get(
        `${API_URL}/Events?page=${current_page ? current_page : page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setComplist(response.data.data);
      setCurrent_page(response.data.current_page);
      setLinnks(response.data.links);

      const initialCheckedStates = response.data.data.reduce((acc, item) => {
        acc[item.id] = item.status === 1;
        return acc;
      }, {});
      setCheckedStates(initialCheckedStates);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  useEffect(() => {
    fetchData(current_page);
  }, [current_page]);
  const handleCheck = async (id) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    try {
      const response = await axios.post(
        `${API_URL}/Events/changeStatus/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const showPopup = () => {
    navigate('/admin/add-events');
  };
  const toggleDropdown = (comp_id) => {
    setVisibleDropdown(visibleDropdown === comp_id ? null : comp_id);
  };



  const editCompetition = (id) => {
    navigate(`/admin/edit-event/${id}`)
  };

 // delete data
 const deleteBanner = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      try {
        axios
        .post(
          `${API_URL}/deleteEvent/${id}`,
          { id: id },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
          .then((response) => {
            fetchData();
            Swal.fire(
              "Deleted!",
              "Data has been deleted.",
              "success"
            );
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              setErrors(error.response.data.error);
            } else {
              console.error("Error:", error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  });
};

    const pagination = (url) => {
    if (url) {
      const page = new URL(url).searchParams.get('page');
      setCurrent_page(Number(page));
    }
  }
  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div
                className="content-wrapper"
                style={{ display: "inline-block" }}
              >
                {/* <!-- Content --> */}
                <ToastContainer />
                {/* <!-- Content --> */}
                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">
                            Event List
                          </span>
                        </h4>
                        <button
                          type="button"
                          className="btn btn-primary rounded-pill"
                          onClick={showPopup}
                        >
                          {" "}
                          <i className="ti ti-plus ti-xs"></i>Add Event
                        </button>
                      </div>
                      <div className="row">
                        <div className="card">
                          {/* <h5 className="card-header">Hoverable rows</h5> */}
                          <div className="table-responsive text-nowrap">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Sr. no</th>
                                  <th>Title</th>
                                  <th>Description</th>
                                  <th>Link</th>
                                  <th>Image</th>
                                  <th>Date</th>
                                  <th>status</th>
                                  <th>action</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {complist.map &&
                                  complist.map((item, index) => (
                                    <tr key={index}>
                                      <td>{(current_page * 10) + index + 1 - 10}</td>
                                      <td>
                                        <span className="fw-medium">
                                          {truncateString(item.title,20)}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fw-medium">
                                        {truncateString(stripHtmlTags(item.description), 20)}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fw-medium">
                                        <a href={item.link} target="_blank" rel="noopener noreferrer">
      {item.link}
    </a>
                                        </span>
                                      </td>
                                      <td>
                                        <img     src={item.image ? `${BACKEND_URL}/storage/images/${item.image}` : `${BACKEND_URL}/storage/images/default.png`} 
                                          height="100px" width="100px" />
                                      </td>
                                      <td>
                                      <span className="fw-medium">
                                          {item.e_date }
                                        </span>
                                      </td>
                                      <td>
                                        <label class="switch">
                                          <input
                                            type="checkbox"
                                            className="switch-input"
                                            checked={checkedStates[item.id] || false}
                                            onChange={() => handleCheck(item.id)}
                                            name="status"
                                          />{" "}
                                          <span class="switch-toggle-slider">
                                            <span class="switch-on"></span>
                                            <span class="switch-off"></span>
                                          </span>
                                          <span class="switch-label">
                                          {checkedStates[item.id] ? "Active" : " Deactive"}
                                          </span>
                                        </label>
                                      </td>
                                      {/* <td>
                                        <div className="dropdown">
                                          <button
                                            ref={dropdownRef}
                                            type="button"
                                            className={`btn p-0 dropdown-toggle hide-arrow ${
                                              visibleDropdown === item.id
                                                ? "show"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              toggleDropdown(item.id)
                                            }
                                          >
                                            <i className="ti ti-dots-vertical"></i>
                                          </button>
                                          <div
                                            className={`dropdown-menu ${
                                              visibleDropdown === item.id
                                                ? "show"
                                                : ""
                                            }`}
                                          >
                                            <a
                                              className="dropdown-item"
                                              onClick={() =>
                                                editCompetition(item.id)
                                              }
                                            >
                                              <i className="ti ti-pencil me-1"></i>{" "}
                                              Edit
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              onClick={() =>
                                                deleteBanner(item.id)
                                              }
                                            >
                                              <i className="ti ti-trash me-1"></i>{" "}
                                              Delete
                                            </a>
                                          </div>
                                        </div>
                                      </td> */}
                                       <td>
                                        <div
                                          className=""
                                          style={{ marginRight: 50 + "px" }}
                                        >
                                          
                                          <div
                                            className="d-flex"
                                          >
                                            <a
                                              className=""
                                              style={{
                                                marginRight: 5 + "px"
                                              }}
                                              onClick={() =>
                                                editCompetition(item.id)
                                              }
                                            >
                                              <i className="ti ti-pencil me-1" style={{
                                                color: "white",
                                                borderRadius: "7.5" + "px",
                                                background: "darkkhaki"
                                              }}></i>{" "}
                                            </a>
                                            <a
                                              className=""
                                              onClick={() =>
                                                deleteBanner(item.id)
                                              }
                                            >
                                              <i className="ti ti-trash me-1" style={{
                                                color: "white",
                                                borderRadius: "7.5" + "px",
                                                background: "#c21027"
                                              }}></i>{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                         
                        </div>
                        <div
                            style={{
                              marginTop: "17px",
                              textAlign: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <nav aria-label="Page navigation">
                            <ul className="pagination">
                              {links && links.map((item, index) => {
                                let label;
                                if (index === 0) {
                                  label = "previous";
                                } else if (index === links.length - 1) {
                                  label = "next";
                                } else {
                                  label = index;
                                }
                                
                                return (
                                  <li key={index} className={`page-item ${item.active ? 'active' : ''}`}>
                                    <a
                                      className="page-link waves-effect"
                                      onClick={() => pagination(item.url)}
                                    >
                                      {label}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                            </nav>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}
                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}
                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page --> */}
          </div>
          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>
          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}
      </div>
    </>
  );
}
