import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { API_URL } from "./Constants";
import { BACKEND_URL } from "./Constantsurl";
import DOMPurify from "dompurify";
import { htmlToText } from "html-to-text";

export default function BlogList() {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const authToken = localStorage.getItem("token");
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [errors, setErrors] = useState({});
  const [blog, setBlog] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/Blog`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setBlog(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const toggleDropdown = (id) => {
    setVisibleDropdown(visibleDropdown === id ? null : id);
  };

  const closeOpenMenus = (e) => {
    if (visibleDropdown && !dropdownRef.current?.contains(e.target)) {
      setTimeout(() => {
        setVisibleDropdown(null);
      }, 300);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [visibleDropdown]);

  useEffect(() => {
    fetchData();
  }, []);

  const goToAddblog = () => {
    navigate("/admin/add-blogs");
  };

  const deleteBlog = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .post(`${API_URL}/deleteBlog/${id}`, { id: id }, {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            })
            .then((response) => {
              fetchData();
            })
            .catch((error) => {
              if (error.response && error.response.data.error) {
                setErrors(error.response.data.error);
              } else {
                console.error("Error:", error);
              }
            });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    });
  };

 

  function truncateHTML(htmlString, maxLength) {
    const strippedString = htmlString.replace(/<[^>]+>/g, ''); // Remove HTML tags
    if (strippedString.length > maxLength) {
      return strippedString.substring(0, maxLength) + '...';
    } else {
      return strippedString;
    }
  }
  
  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div className="content-wrapper" style={{ display: "inline-block" }}>
                {/* <!-- Content --> */}
                <ToastContainer />
                {/* <!-- Content --> */}
                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">Blog List</span>
                        </h4>
                        <button
                          type="button"
                          className="btn btn-primary rounded-pill"
                          onClick={goToAddblog}
                        >
                          <i className="ti ti-plus ti-xs"></i>Add Blog
                        </button>
                      </div>
                      <div className="row">
                        <div className="card">
                          <div className="table-responsive text-nowrap">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Sr. no</th>
                                  <th>Category</th>
                                  <th>Heading</th>
                                  <th>Description</th>
                                  <th>Blog</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {blog.map &&
                                  blog.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <span className="fw-medium">
                                          {item.category}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fw-medium">
                                          {item.heading}
                                        </span>
                                      </td>
                                      <td>
                                        <span>{truncateHTML(item.description, 20)}</span>
                                      </td>
                                      <td>
                                        <img
                                          width="100px"
                                          height="80px"
                                          src={`${BACKEND_URL}/storage/images/blogs/${item.image}`}
                                        />
                                      </td>
                                      <td>
                                        <div className="" style={{ marginRight: 50 + "px" }}>
                                          <div className="d-flex">
                                            <a
                                              style={{ marginRight: 5 + "px" }}
                                              onClick={() => navigate(`/admin/edit-blog/${item.id}`)}
                                            >
                                              <i className="ti ti-pencil me-1" style={{
                                                color: "white",
                                                borderRadius: "7.5" + "px",
                                                background: "darkkhaki"
                                              }}></i>{" "}
                                            </a>
                                            <a onClick={() => deleteBlog(item.id)}>
                                              <i className="ti ti-trash me-1" style={{
                                                color: "white",
                                                borderRadius: "7.5" + "px",
                                                background: "#c21027"
                                              }}></i>{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}

                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}
                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page --> */}
          </div>
          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>
          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}
      </div>
    </>
  );
}
