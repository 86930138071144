import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Button from "./Elements/Button";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "./Constants";
import { BACKEND_URL } from "./Constantsurl";

export default function EditEvent() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    e_date: "",
    description: "",
    link: "",
    status: false,
  });  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [imageInput, setImageInput] = useState("");
  const authToken = localStorage.getItem("token");
  const [imageError, setImageError] = useState(true);

  const handleQuillChange = (value) => {
    setFormData({ ...formData, description: value });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };



  const handleStatusChange = (e) => {
    setFormData({ ...formData, status: e.target.checked });
  };

  const imageChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024;

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: "Only JPG, JPEG, and PNG files are allowed." });
        setImageError(false);
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: "File size should not exceed 2 MB." });
        setImageError(false);
        return;
      }
      setFile(selectedFile);
      setImageInput(URL.createObjectURL(selectedFile));
      setFormData({ ...formData, imageInput: selectedFile.name });
      setImageError(true);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/editEvent/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setImageInput(`${BACKEND_URL}/storage/images/${response.data.image}`);
      setFormData({
        ...response.data,
        description: response.data.description,
        link: response.data.link || "",
        status: response.data.status === 1 // Convert status to boolean
      });
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [id, authToken]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const validateFormData = () => {
    let errors = {};

    if (!formData.title || !formData.title.trim()) {
      errors.title = "Title is required";
    }
    if (!formData.e_date || !formData.e_date.trim()) {
      errors.e_date = "Date is required";
    }
    if (!formData.description || !formData.description.trim()) {
      errors.description = "Description is required";
    }
    if (!formData.link || !formData.link.trim()) {
      errors.link = "Link is required";
    }
    if (!imageError) {
      return;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData()) {
      const newFormData = new FormData();
      newFormData.append("formData", JSON.stringify(formData));
      newFormData.append("status", formData.status ? "true" : "false"); // Send status as "true" or "false"

      if (file) {
        newFormData.append("imageInput", file);
      }

      try {
        axios
          .post(`${API_URL}/editEvent/${id}`, newFormData, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast.success(response.data.message);
            navigate("/admin/events");
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              setErrors(error.response.data.error);
            } else {
              console.error("Error:", error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <div className="layout-page">
              <div className="content-wrapper" style={{ display: "inline-block" }}>
                <ToastContainer />
                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">Events</span>
                          <h6 className="text-muted">Fill Events</h6>
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit} method="post">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <label className="form-label">
                              Title<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="100"
                              name="title"
                              value={formData.title}
                              onChange={handleChange}
                              placeholder="Enter Title"
                            />
                            {errors.title && (
                              <div className="error text-danger">
                                {errors.title}
                              </div>
                            )}
                          </div>
                          <div className="col-6">
                            <label className="form-label">
                              Choose Date<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              name="e_date"
                              value={formData.e_date}
                              onChange={handleChange}
                            />
                            {errors.e_date && (
                              <div className="error text-danger">
                                {errors.e_date}
                              </div>
                            )}
                          </div>
                          <div className="col-6">
                            <label htmlFor="formFile" className="form-label">
                              Upload Image<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="image"
                              onChange={imageChange}
                            />
                            <div className="text-muted">
                              Allowed JPG, JPEG or PNG. Max size of 2mb
                            </div>
                            {errors.imageInput && (
                              <div className="error text-danger">
                                {errors.imageInput}
                              </div>
                            )}
                            {imageInput && (
                              <img
                                className="form-control"
                                src={imageInput}
                                height="150px"
                                style={{
                                  width: "180px",
                                  marginTop: "20px",
                                }}
                              />
                            )}
                          </div>
                          {/* Link Input Field */}
                          <div className="col-12 mt-4">
                            <label className="form-label">
                              Event Link<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="link"
                              value={formData.link}
                              onChange={handleChange}
                              placeholder="Enter Event Link"
                            />
                            {errors.link && (
                              <div className="error text-danger">
                                {errors.link}
                              </div>
                            )}
                          </div>
                          <div className="col-12 mt-4">
                            <label className="form-label">
                              Description<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="card">
                              <ReactQuill
                                theme="snow"
                                value={formData.description || ""}
                                onChange={handleQuillChange}
                                style={{ minHeight: "300px" }}
                                name="description"
                              />
                            </div>
                            {errors.description && (
                              <div className="error text-danger">
                                {errors.description}
                              </div>
                            )}
                          </div>
                          <div className="col-12 m-4">
                            <label className="form-label" htmlFor="defaultCheck2">
                              Active Status
                            </label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="status"
                                id="flexSwitchCheckDefault"
                                checked={formData.status || false}
                                onChange={handleStatusChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <Button text="Update" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
