// Components/PageNotFound.jsx

import React from 'react';
import './PageNotFound.css'; // Import the CSS file for styling

const PageNotFound = () => {
  return (
    <div className="page-not-found-container">
      <div className="error-code">404</div>
      <p className="error-message">Oops! The page you're looking for does not exist.</p>
      <a href="/" className="back-home-btn">Go Back Home</a>
    </div>
  );
};

export default PageNotFound;
