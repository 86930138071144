import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "./Elements/Button";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./Constants";

export default function PrivacyPolicy() {
  const [quillInput, setQuillInput] = useState({description: ""});
  const authToken = localStorage.getItem("token");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/getTermsAndConditions`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
           setQuillInput(response.data[0].name);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
  
    fetchData();
  }, []);
  const handleQuillChange = (value) => {
    setQuillInput(value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      "quillInput": quillInput
    }
    try {
      axios.post(`${API_URL}/postTermsAndConditions`, data, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
        })
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data.error) {
            setErrors(error.response.data.error);
          } else {
            console.error("Error:", error);
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar  ">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div
                className="content-wrapper"
                style={{ display: "inline-block" }}
              >
                {/* <!-- Content --> */}
                <ToastContainer />
                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="">
                          <span className=" fw-light text-dark">
                          Terms And Conditions Details
                          </span>
                          <h6 className="text-muted">Fill Terms And Conditions Details</h6>
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit}>
                      <div className="row">
                        {/* <!-- Full Editor --> */}
                        <div className="col-12">
                          <div className="card">
                            <ReactQuill
                              theme="snow"
                              name="description"
                              value={quillInput}
                              onChange={handleQuillChange}
                              style={{ minHeight: "300px" }}
                            />
                          </div>
                        </div>
                       
                        {/* <!-- /Full Editor --> */}
                      </div>
                      <Button text="Update" className="btn btn-primary me-sm-3 me-1" />
                      </form>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}

                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}

                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page -->/ */}
          </div>

          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>

          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}
      </div>
    </>
  );
}
