import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Login from "./Components/Login";
import Register from "./Components/Register";
import ForgetPassword from "./Components/ForgetPassword";
import Dashboard from "./Components/Dashboard";
import Profile from "./Components/Profile";
import Aboutus from "./Components/Admin/AboutUs";
import Layout from "./Components/Admin/Layout/Layout";
import PrivacyPolicy from "./Components/Admin/PrivacyPolicy";
import ReturnPolicy from "./Components/Admin/ReturnPolicy";
import TermsAndConditions from "./Components/Admin/TermsAndConditions";
import Faq from "./Components/Admin/Faq";
import CompanySetting from "./Components/Admin/CompanySetting";
import SmtpSetting from "./Components/Admin/SmtpSetting";
import Banner from "./Components/Admin/Banner";
import BannerList from "./Components/Admin/BannerList";
import EditBanner from "./Components/Admin/EditBanner";
import Competition from "./Components/Admin/Competiton";
import CompetitionDetails from "./Components/CompetitionDetails";
import AddCompettionDetails from "./Components/AddCompetitionDetails";
import EditCompetitionDetails from "./Components/EditCompetitionDetail";
import Events from "./Components/Admin/Events";
import AddEvents from "./Components/AddEvent";
import EditEvent from "./Components/Admin/EditEvent";
import ProtectedRoute from "./Components/Admin/Layout/ProtectedRoute";
import Blogs from "./Components/Admin/Blogs";
import BlogList from "./Components/Admin/BlogList";
import EditBlog from "./Components/Admin/EditBlog";
import StoryList from "./Components/Admin/StoryList";
import EditStory from "./Components/Admin/EditStory";
import AddStory from "./Components/Admin/AddStory";
import UserList from "./Components/Admin/UserList";
import QueryList from "./Components/Admin/QueryList";
import PageNotFound from './Components/PageNotFound';
import CompanyCompetitions from './Components/CompanyCompititions';
import EditCompanyCompetitionDetails from "./Components/EditCompanyCompetitionDetails";




function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    const userToken = sessionStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <div className="App">
      <Router>
        <ConditionalLayout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/admin/cms/about-us" element={<ProtectedRoute><Aboutus /></ProtectedRoute>} />
            <Route path="/admin/cms/privacy-policy" element={<ProtectedRoute><PrivacyPolicy /></ProtectedRoute>} />
            <Route path="/admin/cms/return-policy" element={<ProtectedRoute><ReturnPolicy /></ProtectedRoute>} />
            <Route path="/admin/cms/terms-and-conditions" element={<ProtectedRoute><TermsAndConditions /></ProtectedRoute>} />
            <Route path="/admin/cms/faq" element={<Faq />} />
            <Route path="/admin/company-setting" element={<ProtectedRoute><CompanySetting /></ProtectedRoute>} />
            <Route path="/admin/smtp-setting" element={<ProtectedRoute><SmtpSetting /></ProtectedRoute>} />
            <Route path="/admin/add-banner" element={<ProtectedRoute><Banner /></ProtectedRoute>} />
            <Route path="/admin/banner-list" element={<ProtectedRoute><BannerList /></ProtectedRoute>} />
            <Route path="/admin/edit-banner/:id" element={<ProtectedRoute><EditBanner /></ProtectedRoute>} />
            <Route path="/admin/competition" element={<ProtectedRoute><Competition /></ProtectedRoute>} />
            <Route path="/admin/competition-details" element={<ProtectedRoute><CompetitionDetails /></ProtectedRoute>} />
            <Route path="/admin/add-competition-details" element={<ProtectedRoute><AddCompettionDetails /></ProtectedRoute>} />
            <Route path="/admin/edit-competition-details/:id" element={<ProtectedRoute><EditCompetitionDetails /></ProtectedRoute>} />
            <Route path="/admin/events" element={<ProtectedRoute><Events /></ProtectedRoute>} />
            <Route path="/admin/add-events" element={<ProtectedRoute><AddEvents /></ProtectedRoute>} />
            <Route path="/admin/edit-event/:id" element={<ProtectedRoute><EditEvent /></ProtectedRoute>} />
            <Route path="/admin/add-blogs" element={<ProtectedRoute><Blogs /></ProtectedRoute>} />
            <Route path="/admin/blog-list" element={<ProtectedRoute><BlogList /></ProtectedRoute>} />
            <Route path="/admin/edit-blog/:id" element={<ProtectedRoute><EditBlog /></ProtectedRoute>} />
            <Route path="/admin/story-list" element={<ProtectedRoute><StoryList /></ProtectedRoute>} />
            <Route path="/admin/edit-story/:id" element={<ProtectedRoute><EditStory /></ProtectedRoute>} />
            <Route path="/admin/add-story" element={<ProtectedRoute><AddStory /></ProtectedRoute>} />
            <Route path="/admin/user-list" element={<ProtectedRoute><UserList /></ProtectedRoute>} />
            <Route path="/admin/query-list" element={<ProtectedRoute><QueryList /></ProtectedRoute>} />
            <Route path="/admin/company-competition" element={<ProtectedRoute><CompanyCompetitions /></ProtectedRoute>} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/admin/editCompanyCompetitionDetails/:id" element={<ProtectedRoute><EditCompanyCompetitionDetails /></ProtectedRoute>} />


            
          </Routes>
        </ConditionalLayout>
      </Router>
      
    </div>
  );
}

const ConditionalLayout = ({ children }) => {
  const location = useLocation();
  const noLayoutPaths = ["/login", "/register", "/forgetPassword"];

  if (noLayoutPaths.includes(location.pathname)) {
    return <>{children}</>;
  }

  return <Layout>{children}</Layout>;
};

export default App;
