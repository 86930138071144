import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { API_URL } from "./Constants";
import DOMPurify from "dompurify";
import { htmlToText } from "html-to-text";
import "../StoryList.css"; // Assuming custom styles for the toggle switch are in this file

export default function StoryList() {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const authToken = localStorage.getItem("token");
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [errors, setErrors] = useState({});
  const [story, setStory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/Story`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setStory(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const toggleDropdown = (id) => {
    setVisibleDropdown(visibleDropdown === id ? null : id);
  };

  const closeOpenMenus = (e) => {
    if (visibleDropdown && !dropdownRef.current?.contains(e.target)) {
      setTimeout(() => {
        setVisibleDropdown(null);
      }, 300);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [visibleDropdown]);

  useEffect(() => {
    fetchData();
  }, []);

  const goToAddStory = () => {
    navigate("/admin/add-story");
  };

  const toggleStatus = async (id, pageName, currentStatus) => {
    const newStatus = currentStatus === 1 ? 2 : 1;
    try {
      await axios.post(`${API_URL}/toggleStatus/${id}/${pageName}`, { status: newStatus }, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success(`Story ${newStatus === 1 ? 'activated' : 'deactivated'} successfully`);
      fetchData();
    } catch (error) {
      console.error("Error toggling status", error);
      toast.error("Failed to toggle status");
    }
  };

  const deleteStory = async (id, pageName) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${API_URL}/deleteStory/${id}/${pageName}`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
          toast.success("Story deleted successfully");
          fetchData();
        } catch (error) {
          console.error("Error deleting story", error);
          toast.error("Failed to delete the story");
        }
      }
    });
  };

  function truncateHTML(htmlString, maxLength) {
    const strippedString = htmlString.replace(/<[^>]+>/g, ''); // Remove HTML tags
    if (strippedString.length > maxLength) {
      return strippedString.substring(0, maxLength) + '...';
    } else {
      return strippedString;
    }
  }

  const handleDescriptionClick = (description) => {
    setModalContent(description);
    setShowModal(true);
  };

  const goToEditStory = (id) => {
    navigate(`/admin/edit-story/${id}`);
  };

  return (
    <>
      <div>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <div className="layout-page">
              <div className="content-wrapper" style={{ display: "inline-block" }}>
                <ToastContainer />
                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">Story List</span>
                        </h4>
                        {/* <button
                          type="button"
                          className="btn btn-primary rounded-pill"
                          onClick={goToAddStory}
                        >
                          <i className="ti ti-plus ti-xs"></i>Add Story
                        </button> */}
                      </div>
                      <div className="row">
                        <div className="card">
                          <div className="table-responsive text-nowrap">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Sr. no</th>
                                  <th>Page Name</th>
                                  <th>Heading</th>
                                  <th>Description</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {story.map &&
                                  story.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>{index + 1}</td>
                                      <td>{item.page_name}</td>
                                      <td>
                                        <span className="fw-medium">{item.heading}</span>
                                      </td>
                                      <td>
                                        <span
                                          onClick={() => handleDescriptionClick(item.description)}
                                          style={{ cursor: "pointer", textDecoration: "underline" }}
                                        >
                                          {truncateHTML(item.description, 20)}
                                        </span>
                                      </td>
                                      <td>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={item.status === 2}
                                            onChange={() => toggleStatus(item.id, item.page_name, item.status)}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          <button
                                            className="btn btn-warning ms-2"
                                            onClick={() => goToEditStory(item.id)}
                                          >
                                            Edit
                                          </button>
                                          <button
                                            className="btn btn-danger ms-2"
                                            onClick={() => deleteStory(item.id, item.page_name)}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-backdrop fade"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Full Description */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Full Description</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modalContent) }} />
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
