import React from "react";
import axios from "axios";
import { useState } from "react";
import Alert from '@mui/material/Alert';
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "./Admin/Constants";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    let errors = {};
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      axios
        .post(`${API_URL}/adminlogin`, {
          email: email,
          password: password,
        })
        .then((response) => {
          if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("email", response.data.user.email);
            localStorage.setItem("is_admin", response.data.user.is_admin);
            navigate("/");
          } else {
            setErrors(response.message);
            setShowAlert(response.data.message);
            setTimeout(() => {
              setShowAlert(false);
            }, 5000);
          }
        })
        .catch((error) => {
          setShowAlert("email or password does not match");
          setTimeout(() => {
            setShowAlert(false);
          }, 5000);
        });
    }
  };

  return (
    <>
      {/* <!-- Content --> */}
      <div className="authentication-wrapper authentication-cover authentication-bg">
        <div className="authentication-inner row">
          {/* <!-- /Left Text --> */}
          <div className="d-none d-lg-flex col-lg-7 p-0">
            <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
              <img
                src="../../assets/img/illustrations/auth-login-illustration-light.png"
                alt="auth-login-cover"
                className="img-fluid my-5 auth-illustration"
                data-app-light-img="illustrations/auth-login-illustration-light.png"
                data-app-dark-img="illustrations/auth-login-illustration-dark.html"
              />
              <img
                src="../../assets/img/illustrations/bg-shape-image-light.png"
                alt="auth-login-cover"
                className="platform-bg"
                data-app-light-img="illustrations/bg-shape-image-light.png"
                data-app-dark-img="illustrations/bg-shape-image-dark.html"
              />
            </div>
          </div>
          {/* <!-- /Left Text --> */}

          {/* <!-- Login --> */}
          <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
            <div className="w-px-400 mx-auto">
              {/* <!-- Logo --> */}
              <div className="app-brand mb-4">
                <img src="/assets/images/logo1.png" alt="Logo" className="img-fluid" /> {/* Use the logo image here */}
              </div>
              {/* <!-- /Logo --> */}
              <h3 className="mb-1">Welcome to Admin! 👋</h3>
              <p className="mb-4">
                Please sign-in to your account and start the adventure
              </p>
              {showAlert && <Alert severity="error">{showAlert}</Alert>}
              <form
                id="formAuthentication"
                className="mb-3"
                action="#"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email or email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    name="email-email"
                    placeholder="Enter your email or email"
                    autoFocus
                  />
                </div>
                {errors.email && (
                  <div className="error text-danger">{errors.email}</div>
                )}
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <Link to="/forgetPassword">
                      <small>Forgot Password?</small>
                    </Link>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"} // Toggle input type
                      id="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      <i className={showPassword ? "ti ti-eye" : "ti ti-eye-off"}></i>
                    </span>
                  </div>
                  {errors.password && (
                    <div className="error text-danger">{errors.password}</div>
                  )}
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember-me"
                    />
                    <label className="form-check-label" htmlFor="remember-me">
                      Remember Me
                    </label>
                  </div>
                </div>
                <button className="btn btn-primary d-grid w-100">Sign in</button>
              </form>

              {/* <p className="text-center">
                <span>New on our platform?</span>
                <Link to="/register">
                  <span>Create an account</span>
                </Link>
              </p> */}
            </div>
          </div>
          {/* <!-- /Login --> */}
        </div>
      </div>

      {/* Content */}

      {/* <div className="buy-now">
        <a
          href="https://1.envato.market/vuexy_admin"
          target="_blank"
          className="btn btn-danger btn-buy-now"
        >
          Buy Now
        </a>
      </div> */}
    </>
  );
}
