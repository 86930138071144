// src/components/Layout.js

import React from 'react';
import Sidebar from './Sidebar';
import NavBar from './Navbar';

const Layout = ({ children }) => {
  return (
   <>
    {/* <!-- Layout wrapper --> */}
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          
          {/* <!-- Menu --> */}
            <Sidebar />
          {/* <!-- / Menu --> */}

          {/* <!-- Layout container --> */}
          <div className="layout-page">
            {/* <!-- Navbar --> */}

            <NavBar />

            {/* <!-- / Navbar --> */}

            {/* <!-- Content wrapper --> */}
            {children}
            {/* <!-- Content wrapper --> */}
          </div>
          {/* <!-- / Layout page --> */}
        </div>

        {/* <!-- Overlay --> */}
        <div className="layout-overlay layout-menu-toggle"></div>

        {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
        <div className="drag-target"></div>
      </div>
      {/* //   <!-- / Layout wrapper --> */}
   </>
  );
};

export default Layout;
