import React from "react";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "./Elements/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RickTextEditor from "./Elements/RichTextEditor";
import { API_URL } from "./Constants";
import { BACKEND_URL } from "./Constantsurl";

function Aboutus() {
  const initialState = {
    title: "",
    address: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [quillInput, setQuillInput] = useState({ description: "" });
  const [imageInput, setImageInput] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [imageError, setImageError] = useState(true);
  const authToken = localStorage.getItem("token");

  // fetch data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/aboutUs`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setFormData((prevFormData) => ({
          ...prevFormData,
          title: response.data.title,
          address: response.data.address,
        }));
        setQuillInput((prevQuillInput) => ({
          ...prevQuillInput,
          description: response.data.description,
        }));
        setImageInput(
          `${BACKEND_URL}/storage/images/${response.data.image}`
        );
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const handleQuillChange = (value) => {
    setQuillInput({ ...quillInput, description: value });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const imageChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024;

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: "Only JPG, JPEG, and PNG files are allowed." });
        setImageError(false)
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: "File size should not exceed 2 MB." });
        setImageError(false)
        return;
      }
      setFile(selectedFile);
      setImageInput(URL.createObjectURL(selectedFile));
      setFormData({ ...formData, imageInput: selectedFile.name });
      setImageError(true)
    }
  };

  const validateFormData = () => {
    let errors = {};

    if (!formData.title?.trim()) {
      errors.title = "title is required";
    }

    if (!formData.address?.trim()) {
      errors.address = "Address is required";
    }
    if (!quillInput.description?.trim() || quillInput.description === '<p><br></p>' ) {
      errors.description = "Description is required";
    }
    if(!imageError){
      return
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData() && !errors.imageInput) {
      const allData = {
        formData,
        quillInput,
      };
      const newFormData = new FormData();
      newFormData.append("allData", JSON.stringify(allData));
      if (imageInput) {
        newFormData.append("imageInput", file);
      }
      try {
        axios
          .post(`${API_URL}/aboutUs`, newFormData, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast.success(response.data.message);
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              setErrors(error.response.data.error);
            } else {
              console.error("Error:", error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar  ">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div
                className="content-wrapper"
                style={{ display: "inline-block" }}
              >
                {/* <!-- Content --> */}
                <ToastContainer />

                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="">
                          <span className=" fw-light text-dark">
                            About Us Details
                          </span>
                          <h6 className="text-muted">Fill About us Details</h6>
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit} method="post">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <label className="form-label">Title<span style={{ color: "red" }}>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              maxlength="100"
                              name="title"
                              value={formData.title}
                              onChange={handleChange}
                              placeholder="Enter title"
                            />
                            {errors.title && (
                              <div className="error text-danger">
                                {errors.title}
                              </div>
                            )}
                              {!errors.title && (
                              <span style={{ color: "#858585" }}>
                                {"max length 100 caracters"}
                              </span>
                            )}
                          </div>
                          {/* <!-- Full Editor --> */}
                          <div className="col-12">
                          <label className="form-label">Description<span style={{ color: "red" }}>*</span></label>
                            <div className="card">
                              <ReactQuill
                                theme="snow"
                                value={quillInput.description}
                                onChange={handleQuillChange}
                                style={{ minHeight: "300px" }}
                                name="description"
                              />
                            </div>
                            {errors.description && (
                              <div className="error text-danger">
                                {errors.description}
                              </div>
                            )}
                          </div>
                          <div className="col-12">
                            <label htmlFor="formFile" class="form-label">
                              Upload About us Image<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="image"
                              onChange={imageChange}
                            />
                              <div className="text-muted">
                            Allowed JPG, JPEG or PNG. Max size of 2mb
                          </div>
                            {errors.imageInput && (
                              <div className="error text-danger">
                                {errors.imageInput}
                              </div>
                            )}
                            <img
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="image"
                              src={imageInput}
                              height="150px"
                              style={{
                                width: "180px",
                                marginTop: "20px",
                              }}
                            />
                          </div>
                          <div className="col-12">
                            <label htmlFor="formFile" class="form-label">
                              Address<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter Address"
                              name="address"
                              maxLength="200"
                              value={formData.address}
                              onChange={handleChange}
                            />
                            {errors.address && (
                              <div className="error text-danger">
                                {errors.address}
                              </div>
                            )}
                          </div>
                        </div>
                        <Button
                          text="Update"
                          className="btn btn-primary me-sm-3 me-1"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}

                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}

                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page -->/ */}
          </div>

          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>

          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}
      </div>
    </>
  );
}
export default Aboutus;
