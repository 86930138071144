import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./Constants";
import "../StoryList.css"; // Assuming custom styles for the toggle switch are in this file

export default function UserList() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users");
    }
  };

  const toggleUserStatus = async (userId, currentStatus) => {
    try {
      const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
      await axios.post(`${API_URL}/toggleUserStatus/${userId}`, { status: newStatus });
      toast.success("User status updated successfully");
      setUsers(users.map(user => (user.id === userId ? { ...user, status: newStatus } : user)));
    } catch (error) {
      console.error("Error updating user status:", error);
      toast.error("Failed to update user status");
    }
  };

  return (
    <div>
      <ToastContainer />
      <h2>User List</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={user.status === 'active'}
                                            onChange={() => toggleUserStatus(user.id, user.status)}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </td>
              <td>
                {/* Additional actions like edit or delete can be added here */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
