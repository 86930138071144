import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import { ToastContainer, toast } from "react-toastify";
import Button from "./Elements/Button";
import { API_URL } from "./Constants";

export default function Blogs() {
  const navigate = useNavigate();
  const initialState = {
    category: "",
    heading: "",
    description: "",
    imageInput: "", // Added imageInput field to store the image name
  };

  const [formData, setFormData] = useState(initialState);
  const [quillInput, setQuillInput] = useState({ description: "" });
  const [imageInput, setImageInput] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const authToken = localStorage.getItem("token");
  const [imageError, setImageError] = useState(true);

  const handleQuillChange = (value) => {
    setQuillInput({ description: value });
    setFormData({ ...formData, description: value });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const imageChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024;

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: "Only JPG, JPEG, and PNG files are allowed." });
        setImageError(false);
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: "File size should not exceed 2 MB." });
        setImageError(false);
        return;
      }
      setFile(selectedFile);
      setImageInput(URL.createObjectURL(selectedFile));
      setFormData({ ...formData, imageInput: selectedFile.name });
      setImageError(true);
    }
  };

  const validateFormData = () => {
    let errors = {};

    if (!formData.category?.trim()) {
      errors.category = "Category is required";
    }

    if (!formData.heading?.trim()) {
      errors.heading = "Heading is required";
    }

    if (!formData.description?.trim()) {
      errors.description = "Description is required";
    }

    if (!formData.imageInput?.trim()) {
      errors.imageInput = "Image is required";
    }

    if (!imageError) {
      return;
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData()) {
      const allData = {
        formData,
      };
      const newFormData = new FormData();
      newFormData.append("allData", JSON.stringify(allData));
      if (imageInput) {
        newFormData.append("imageInput", file);
      }

      try {
        axios
          .post(`${API_URL}/addBlog`, newFormData, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            navigate("/admin/blog-list");
            toast.success(response.data.message);
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              setErrors(error.response.data.error);
            } else {
              console.error("Error:", error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <div className="layout-page">
              <div className="content-wrapper" style={{ display: "inline-block" }}>
                <ToastContainer />
                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">Blog Details</span>
                          <h6 className="text-muted">Fill Blog Details</h6>
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit} method="post">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <label className="form-label">
                              Category<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="category"
                              value={formData.category}
                              onChange={handleChange}
                              placeholder="Enter category"
                            />
                            {errors.category && (
                              <div className="error text-danger">{errors.category}</div>
                            )}
                          </div>
                          <div className="col-12 mb-4">
                            <label className="form-label">
                              Heading<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="200"
                              name="heading"
                              value={formData.heading}
                              onChange={handleChange}
                              placeholder="Enter heading"
                            />
                            {errors.heading && (
                              <div className="error text-danger">{errors.heading}</div>
                            )}
                            {!errors.heading && (
                              <span style={{ color: "#858585" }}>
                                {"max length 200 characters"}
                              </span>
                            )}
                          </div>
                          <div className="col-12 mb-4">
                            <label className="form-label">
                              Image<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              onChange={imageChange}
                            />
                            {errors.imageInput && (
                              <div className="error text-danger">{errors.imageInput}</div>
                            )}
                            {imageInput && (
                              <img
                                src={imageInput}
                                alt="Selected"
                                style={{ width: "100px", marginTop: "10px" }}
                              />
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Description<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="card">
                              <ReactQuill
                                theme="snow"
                                value={quillInput.description}
                                onChange={handleQuillChange}
                                style={{ minHeight: "300px" }}
                                name="description"
                              />
                            </div>
                            {errors.description && (
                              <div className="error text-danger">{errors.description}</div>
                            )}
                          </div>
                        </div>
                        <Button text="Submit" className="btn btn-primary me-sm-3 me-1" />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="content-backdrop fade"></div>
              </div>
            </div>
          </div>
          <div className="layout-overlay layout-menu-toggle"></div>
          <div className="drag-target"></div>
        </div>
      </div>
    </>
  );
}
