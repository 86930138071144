import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "./Admin/Elements/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Footer from "./Admin/Layout/Footer";
import { API_URL } from "./Admin/Constants";

export default function AddCompetitionDetails() {
  const initialState = {
    category: "",
    heading: "",
    link: "",
    date: "",
    Mode: "",
    ageGroup: "",
    country: "",
  };
  const [complist, setComplist] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const authToken = localStorage.getItem("token");
  const [imageError, setImageError] = useState(true);
  const [quillInput, setQuillInput] = useState({ description: "" });
  const [formData, setFormData] = useState(initialState);
  const [imageInput, setImageInput] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);

  const handleStatusChange = (e) => {
    setFormData({ ...formData, status: e.target.checked });
  };

  const fetchData = async () => {
    try {
      // Fetch competition details
      const response = await axios.get(`${API_URL}/competitionforDetails`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setComplist(response.data);

      // Fetch countries
      const countryResponse = await axios.get(`${API_URL}/countries`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setCountryList(countryResponse.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleQuillChange = (value) => {
    setQuillInput({ ...quillInput, description: value });
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleModeChange = (e) => {
    setFormData({ ...formData, Mode: e.target.value });
  };

  const handleAgeGroupChange = (e) => {
    setFormData({ ...formData, ageGroup: e.target.value });
  };

  const imageChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const maxSize = 2 * 1024 * 1024;

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: 'Only JPG, JPEG, and PNG files are allowed.' });
        setImageError(false);
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: 'File size should not exceed 2 MB.' });
        setImageError(false);
        return;
      }
      
      setFile(selectedFile);
      setImageInput(URL.createObjectURL(selectedFile));
      setFormData({ ...formData, imageInput: selectedFile.name });
      setImageError(true);
    }
  };

  const validateFormData = () => {
    let errors = {};

    if (!formData.category?.trim()) {
      errors.category = "Category is required";
    }
    if (!formData.date?.trim()) {
      errors.date = "Date is required";
    }
    if (!formData.imageInput?.trim()) {
      errors.imageInput = "Image is required";
    }
    if (!formData.heading?.trim()) {
      errors.heading = "Heading is required";
    }
    if (!formData.link?.trim()) {
      errors.link = "Link is required";
    }
    if (!quillInput.description?.trim()) {
      errors.description = "Description is required";
    }
    if (!formData.Mode?.trim()) {
      errors.Mode = " Mode is required";
    }
    if (!formData.ageGroup?.trim()) {
      errors.ageGroup = "Age Group is required";
    }
    if (!formData.country?.trim()) {
      errors.country = "Country is required";
    }
    if (formData.link && !/^https?:\/\/[^\s$.?#].[^\s]*$/i.test(formData.link)) {
      errors.link = "Please enter a valid URL";
    }
    if (!imageError) {
      return;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData()) {
      const allData = {
        ...formData,
        category: formData.category,
        quillInput: quillInput.description
      };
      const newFormData = new FormData();
      newFormData.append("allData", JSON.stringify(allData));
      if (imageInput) {
        newFormData.append("imageInput", file);
        newFormData.append("status", formData.status || false);
      }
      try {
        axios
          .post(
            `${API_URL}/addCompetitionDetails`,
            newFormData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            toast.success(response.data.message);
            const message = response.data.message;
            setTimeout(() => {
              navigate("/admin/competition-details", { state: { message } });
            }, 1000);
          })
          .catch((error) => {
            if (error.response && error.response.data.errors) {
              setErrors(error.response.data.errors);
            } else {
              console.error(error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div className="content-wrapper" style={{ display: "inline-block" }}>
                {/* <!-- Content --> */}
                <ToastContainer />

                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">Competition Details</span>
                          <h6 className="text-muted">Fill Competition Details</h6>
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit} method="post">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Select Category<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="category"
                              onChange={handleChange}
                              value={formData.category}
                            >
                              <option value="" disabled>Select Category</option>
                              {complist.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.category && (
                              <div className="error text-danger">
                                {errors.category}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <label className="form-label">
                              Choose Date<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              name="date"
                              onChange={handleChange}
                              value={formData.date}
                            />
                            {errors.date && (
                              <div className="error text-danger">
                                {errors.date}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <label htmlFor="formFile" className="form-label">
                              Upload Image<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              name="imageInput"
                              onChange={imageChange}
                            />
                            <div className="text-muted">
                              Allowed JPG, JPEG or PNG. Max size of 2mb
                            </div>
                            {errors.imageInput && (
                              <div className="error text-danger">
                                {errors.imageInput}
                              </div>
                            )}
                            {file && (
                              <img
                                className="form-control"
                                src={imageInput}
                                alt="Selected"
                                height="150px"
                                style={{
                                  width: "180px",
                                  marginTop: "20px",
                                }}
                              />
                            )}
                          </div>
                          <div className="col-4">
                            <label className="form-label">
                              Country Mode<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="Mode"
                              onChange={handleModeChange}
                              value={formData.Mode}
                            >
                              <option value="" disabled>Select Mode</option>
                              <option value="1">Online</option>
                              <option value="2">Offline</option>
                            </select>
                            {errors.Mode && (
                              <div className="error text-danger">
                                {errors.Mode}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <label className="form-label">
                              Country<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="country"
                              onChange={handleChange}
                              value={formData.country}
                            >
                              <option value="" disabled>Select Country</option>
                              {countryList.map((country, index) => (
                                <option key={index} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            {errors.country && (
                              <div className="error text-danger">
                                {errors.country}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <label className="form-label">
                              Age Group<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="ageGroup"
                              onChange={handleAgeGroupChange}
                              value={formData.ageGroup}
                            >
                              <option value="" disabled>Select age group</option>
                              <option value="Children">Children</option>
                              <option value="Teenagers">Teenagers</option>
                              <option value="Adults">Adults</option>
                              <option value="Seniors">Seniors</option>
                            </select>
                            {errors.ageGroup && (
                              <div className="error text-danger">
                                {errors.ageGroup}
                              </div>
                            )}
                          </div>

                          <div className="col-4">
                            <label className="form-label">
                              Heading<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="heading"
                              onChange={handleChange}
                              value={formData.heading}
                            />
                            {errors.heading && (
                              <div className="error text-danger">
                                {errors.heading}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <label className="form-label">
                              Link<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="link"
                              onChange={handleChange}
                              value={formData.link}
                            />
                            {errors.link && (
                              <div className="error text-danger">
                                {errors.link}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <label className="form-label">Description</label>
                          <ReactQuill
                            theme="snow"
                            value={quillInput.description}
                            onChange={handleQuillChange}
                          />
                          {errors.description && (
                            <div className="error text-danger">
                              {errors.description}
                            </div>
                          )}
                        </div>
                        <div className="form-check mt-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="status"
                            onChange={handleStatusChange}
                          />
                          <label className="form-check-label" htmlFor="status">
                            Status
                          </label>
                        </div>
                        <div className="text-end mt-4">
                          <Button type="submit" text="Save" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}
              </div>
              {/* <!-- / Content wrapper --> */}
            </div>
            {/* <!-- / Layout container --> */}
          </div>
          {/* <!-- / Layout wrapper --> */}
        </div>
       
      </div>
    </>
  );
}
