import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./Constants";

export default function EditStory() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await axios.get(`${API_URL}/storyEdit/${id}`);
        console.log("Fetched story:", response.data);
        const story = response.data;
        setTitle(story.heading);
        setDescription(story.description);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching story", error);
        toast.error("Failed to fetch story details");
        setLoading(false);
      }
    };

    fetchStory();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      await axios.put(
        `${API_URL}/updateStory/${id}`,
        { heading: title, description },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Story updated successfully");
      // Delay navigation to allow the toast to be displayed
      setTimeout(() => {
        navigate("/admin/story-list"); // Redirect to the story list or any other page
      }, 1000); // Adjust the delay as needed (1000ms = 1 second)
    } catch (error) {
      console.error("Error updating story", error);
      toast.error("Failed to update story");
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="container">
        <h2>Edit Story</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                className={`form-control ${errors.heading ? 'is-invalid' : ''}`}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {errors.heading && <div className="invalid-feedback">{errors.heading}</div>}
            </div>
            <div className="form-group mt-3">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                rows="5"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              {errors.description && <div className="invalid-feedback">{errors.description}</div>}
            </div>
            <button type="submit" className="btn btn-primary mt-3">
              Save Changes
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
