import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "./Admin/Elements/Button";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API_URL } from "./Admin/Constants";
import { BACKEND_URL } from "./Admin/Constantsurl";

export default function EditCompanyCompetitionDetails() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [countryList, setCountryList] = useState([]);
  const [complist, setComplist] = useState([]);
  const [quillInput, setQuillInput] = useState({ description: "" });
  const [banner, setBanner] = useState("");
  const [file, setFile] = useState(null);
  const [imageInput, setImageInput] = useState("");
  const [imageError, setImageError] = useState(true);
  const authToken = localStorage.getItem("token");

  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    title: '',
    comp_date: '',
    organiserUrl: '',
    competitionUrl: '',
    organiserName: '',
    competitionEmail: '',
    yourEmail: '',
    competitionDeadline: '',
    prizeType: '',
    prizeSummary: '',
    regions: [],
    education: [],
    category: [],
    description: '',
    status: false,
  });

  const prizeTypeOptions = [
    { value: "cash", label: "Cash" },
    { value: "scholarship", label: "Scholarship" },
    { value: "certificate", label: "Certificate" },
    { value: "gift", label: "Gift" },
    { value: "other", label: "Other" },
  ];
  
  const regionOptions = [
    { value: "global", label: "Global" },
    { value: "europe", label: "Europe" },
    { value: "asia", label: "Asia" },
    { value: "africa", label: "Africa" },
    { value: "southAmerica", label: "South America" },
    { value: "northAmerica", label: "North America" },
    { value: "oceania", label: "Oceania" },
    { value: "europeanUnion", label: "European Union(UI)" },
  ];

  const educationOptions = [
    { value: "chooseAll", label: "Choose All" },
    { value: "Phd", label: "Phd" },
    { value: "High school Students", label: "High school Students" },
    { value: "Master Students", label: "Master Students" },
    { value: "Bachelor Students", label: "Bachelor Students" },
    { value: "Open to everyone", label: "Open to everyone" },
  ];

  const categoryOptions = [
    { value: "Arts/Photography", label: "Arts/Photography" },
    { value: "Design/Architecture", label: "Design/Architecture" },
    { value: "Mathematics", label: "Mathematics" },
    { value: "Law", label: "Law" },
    { value: "Fashion", label: "Fashion" },
    { value: "Education", label: "Education" },
    { value: "Natural Sciences", label: "Natural Sciences" },
    { value: "Tech & Engineering", label: "Tech & Engineering" },
    { value: "Business", label: "Business" },
    { value: "Social Sciences", label: "Social Sciences" },
    { value: "Health", label: "Health" },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const imageChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: "Only JPG, JPEG, and PNG files are allowed." });
        setImageError(false);
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: "File size should not exceed 2 MB." });
        setImageError(false);
        return;
      }

      setFile(selectedFile);
      setImageInput(URL.createObjectURL(selectedFile));
      setFormData({ ...formData, imageInput: selectedFile.name });
      setImageError(true);
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;

    setFormData((prevForm) => {
      let newArray = prevForm[name] || [];
      if (checked) {
        newArray = [...newArray, value];
      } else {
        newArray = newArray.filter((item) => item !== value);
      }

      return {
        ...prevForm,
        [name]: newArray,
      };
    });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/editCompanyCompetitionDetails/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const parsedCategory = JSON.parse(response.data[0].category || "[]");
      const parsedRegions = JSON.parse(response.data[0].regions || "[]");
      const parsedEducation = JSON.parse(response.data[0].education || "[]");

      setFormData({
        comp_date: response.data[0].comp_date,
        title: response.data[0].title,
        id: response.data[0].id,
        organiserUrl: response.data[0].organiser_url,
        competitionUrl: response.data[0].competition_url,
        organiserName: response.data[0].organiser_name,
        competitionEmail: response.data[0].competition_email,
        yourEmail: response.data[0].your_email,
        competitionDeadline: response.data[0].competition_deadline,
        prizeType: response.data[0].prize_type,
        prizeSummary: response.data[0].prize_summary,
        regions: Array.isArray(parsedRegions) ? parsedRegions : [],
        education: Array.isArray(parsedEducation) ? parsedEducation : [],
        category: Array.isArray(parsedCategory) ? parsedCategory : [],
        status: response.data[0].status,
        description: response.data[0].description ?? '',
      });
      setImageInput(`${BACKEND_URL}/storage/images/company/${response.data[0].competition_image}`);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const validateStep = () => {
    let errors = {};
    console.log('formData.title:', formData.title);

    if (!formData.title.trim()) {
      errors.title = "Title is required";
    }
    // if (!formData.competitionImage) {
    //   errors.competitionImage = "Competition image is required";
    // }
    if (!formData.organiserUrl.trim()) {
      errors.organiserUrl = "Organizer URL is required";
    }
    if (!formData.competitionUrl.trim()) {
      errors.competitionUrl = "Competition URL is required";
    }
    if (!formData.organiserName.trim()) {
      errors.organiserName = "Organizer name is required";
    }
    if (!formData.competitionEmail.trim()) {
      errors.competitionEmail = "Competition email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.competitionEmail)) {
      errors.competitionEmail = "Competition email is invalid";
    }
    if (!formData.yourEmail.trim()) {
      errors.yourEmail = "Your email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.yourEmail)) {
      errors.yourEmail = "Your email is invalid";
    }
    if (!formData.competitionDeadline.trim()) {
      errors.competitionDeadline = "Competition deadline is required";
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
    }
    if (!formData.prizeType.trim()) {
      errors.prizeType = "Prize type is required";
    }
    if (!formData.prizeSummary.trim()) {
      errors.prizeSummary = "Prize summary is required";
    }
    if (!formData.regions.length) {
      errors.regions = "At least one region is required";
    }
    if (!formData.education.length) {
      errors.education = "At least one education level is required";
    }
    if (!formData.category.length) {
      errors.category = "At least one category is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submit triggered");

    if (validateStep()) {
        console.log("Form is valid, preparing submission");

      const allData = {
        ...formData,
        quillInput: quillInput.description,
        status: formData.status ? "true" : "false",
      };
  
      const submissionData = new FormData();
      submissionData.append("allData", JSON.stringify(allData));
      submissionData.append("quillInput", quillInput.description);
  
      // Only append the image if it exists
      if (file) {
        submissionData.append("imageInput", file);
      }
  
      console.log("Submitting formData:", allData);
  
      axios
        .post(`${API_URL}/editPostCompanyCompetitionDetails/${id}`, submissionData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // e.target.reset();  // Reset form after successful submission
          // setCurrentStep(1); // Reset to the first step if needed
          // toast.success(response.data.message); // Display success message
          toast.success(response.data.message);
          navigate("/admin/company-competition");
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          // Log error and show feedback
          if (error.response && error.response.data.errors) {
            setErrors(error.response.data.errors);
          } else {
            toast.error("An unexpected error occurred");
          }
        });
    } else {
        console.log("Form validation failed");

    }
  };
  

  return (
    <div>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <div className="layout-page">
              <div className="content-wrapper" style={{ display: "inline-block" }}>
                <ToastContainer />

                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">Company Competition Details</span>
                          <h6 className="text-muted">Edit Competition</h6>
                        </h4>
                      </div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {/* Form Fields */}
        {/* Title */}
        <div className="row"> 
        <div className="col-lg-6 col-sm-6">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title || ""}
            onChange={handleChange}
             className="form-control"
          />
          {errors.title && <span style={{ color: "red" }}>{errors.title}</span>}
          </div>
        </div>

  {/* Image Upload */}
        
        <div className="row">
        <div className="col-lg-6 col-sm-6">
          <label htmlFor="competitionImage">Competition Image</label>
          <input type="file" id="competitionImage" onChange={imageChange} />
          {imageInput && <img src={imageInput} alt="Competition" />}
          {errors.imageInput && <p>{errors.imageInput}</p>}
        </div>
        </div>

        {/* Organizer URL */}
        <div className="row"> 
        <div className="col-lg-6 col-sm-6">
          <label htmlFor="organiserUrl">Organizer URL</label>
          <input
            type="text"
            id="organiserUrl"
            name="organiserUrl"
            value={formData.organiserUrl || ""}
            onChange={handleChange}
             className="form-control"
          />
          {errors.organiserUrl && <span style={{ color: "red" }}>{errors.organiserUrl}</span>}
        </div>
        <div  className="col-lg-6 col-sm-6">
          <label htmlFor="competitionUrl">Competition URL</label>
          <input
            type="text"
            id="competitionUrl"
            name="competitionUrl"
            value={formData.competitionUrl || ""}
            onChange={handleChange}
             className="form-control"
          />
          {errors.competitionUrl && <span style={{ color: "red" }}>{errors.competitionUrl}</span>}
        </div>
        </div>

        <div className="row"> 
        <div className="col-lg-6 col-sm-6">
          <label htmlFor="organiserUrl">Organizer Name</label>
          <input
            type="text"
            id="organiserName"
            name="organiserName"
            value={formData.organiserName || ""}
            onChange={handleChange}
             className="form-control"
          />
          {errors.organiserName && <span style={{ color: "red" }}>{errors.organiserName}</span>}
        </div>
      
        </div>
        <div className="row"> 
        <div className="col-lg-6 col-sm-6">
          <label htmlFor="competitionEmail">Competition Email</label>
          <input
            type="text"
            id="competitionEmail"
            name="competitionEmail"
            value={formData.competitionEmail || ""}
            onChange={handleChange}
             className="form-control"
          />
          {errors.competitionEmail && <span style={{ color: "red" }}>{errors.competitionEmail}</span>}
        </div>
        <div  className="col-lg-6 col-sm-6">
          <label htmlFor="yourEmail">Your Email</label>
          <input
            type="text"
            id="yourEmail"
            name="competitionUrl"
            value={formData.yourEmail || ""}
            onChange={handleChange}
             className="form-control"
          />
          {errors.yourEmail && <span style={{ color: "red" }}>{errors.yourEmail}</span>}
        </div>
        </div>
        <div className="row">
        <div  className="col-lg-6 col-sm-6">
          <label htmlFor="competitionDeadline">Competition Deadline</label>
          <input
            type="date"
            id="competitionDeadline"
            name="competitionDeadline"
            value={formData.competitionDeadline || ""}
            onChange={handleChange}
             className="form-control"
          />
          {errors.competitionDeadline && <span style={{ color: "red" }}>{errors.competitionDeadline}</span>}
        </div>
        <div  className="col-lg-6 col-sm-6">
          <label htmlFor="competitionDeadline">Competition Deadline</label>
          <select
                            name="prizeType"
                            id="prizeType"
                            onChange={handleChange}
                            value={formData.prizeType}
                            className="form-control"
                          >
                            <option value="">Select Prize Type</option>
                            {prizeTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
          {errors.prizeType && <span style={{ color: "red" }}>{errors.prizeType}</span>}
        </div>

        <div  className="col-lg-6 col-sm-6">
          <label htmlFor="prizeSummary">Prize Summary</label>
          <textarea
                            name="prizeSummary"
                            id="prizeSummary"
                            onChange={handleChange}
                            value={formData.prizeSummary}
                            className="form-control"
                            placeholder="Prize Summary"
                          ></textarea>
          {errors.prizeSummary && <span style={{ color: "red" }}>{errors.prizeSummary}</span>}
        </div>
        </div>
<div className="row">
<div  className="col-lg-12 col-sm-12">
<label htmlFor="description">Description</label>

<textarea
                            name="description"
                            id="description"
                            onChange={handleChange}
                            value={formData.description}
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                            {errors.description && <span style={{ color: "red" }}>{errors.description}</span>}
                          
  </div>
</div>


        {/* Add other fields similarly */}
        {/* For checkboxes */}


        
        <div className="row">
        <div  className="col-lg-6 col-sm-6">

          <label>Regions:</label>
          {regionOptions.map((option) => (
            <div key={option.value}>
              <input
               className="form-check-input"
                type="checkbox"
                name="regions"
                value={option.value}
                checked={formData.regions.includes(option.value)}
                onChange={handleCheckboxChange}
              />
              <label>{option.label}</label>

            </div>              

            
          ))}
                      {errors.regions && <span style={{ color: "red" }}>{errors.regions}</span>}

        </div>
        <div  className="col-lg-6 col-sm-6">

          <label>Education:</label>
          {educationOptions.map((edu) => (
            <div key={edu.value}>
              <input
               className="form-check-input"
                type="checkbox"
                name="education"
                value={edu.value}
                checked={formData.education.includes(edu.value)}
                onChange={handleCheckboxChange}
              />


              <label>{edu.label}</label>
            </div>
          ))}
                                {errors.education && <span style={{ color: "red" }}>{errors.education}</span>}

        </div>
        </div>
        <div className="row">
        <div  className="col-lg-6 col-sm-6">

<label>Category:</label>
{categoryOptions.map((category) => (
  <div key={category.value}>
    <input
     className="form-check-input"
      type="checkbox"
      name="category"
      value={category.value}
      checked={formData.category.includes(category.value)}
      onChange={handleCheckboxChange}
      
    />


    <label>{category.label}</label>
  </div>
))}
</div>
        </div>

        {/* Submit Button */}
        <div className="text-end mt-4">
                          <Button type="submit" text="Save" />
                        </div>
      </form>
      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
}
