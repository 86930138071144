import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "./Elements/Button";
import Swal from "sweetalert2";
import { API_URL } from "./Constants";
import { BACKEND_URL } from "./Constantsurl";

export default function Competition() {
  const authToken = localStorage.getItem("token");
  const dropdownRef = useRef(null);
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [isPopupVisible, setisPopupVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [imageInput, setImageInput] = useState("");
  const [file, setFile] = useState(null);
  const [current_page, setCurrent_page] = useState("1");
  const [links, setLinnks] = useState([]);
  const [complist, setComplist] = useState({});
  const [imageError, setImageError] = useState(true);
  const [checkedStates, setCheckedStates] = useState({});

  const [comp, setComp] = useState({
    competition: "",
    competition_description: "",
    id: "",
    status: false,
  });
  // when type into inputs
  const handleComp = (e) => {
    var compName = e.target.name;
    var compValue = e.target.value;
    setComp((values) => ({ ...values, [compName]: compValue }));
  };
  const handleStatusChange = (e) => {
    setComp({ ...comp, status: e.target.checked });
  };
  // click outside to hide the popup
  const closeOpenMenus = (e) => {
    if (visibleDropdown && !dropdownRef.current?.contains(e.target)) {
      setTimeout(() => {
        setVisibleDropdown(null);
      }, 300);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [visibleDropdown]);

  // fetch data
  const fetchData = async (page) => {
    try {
      const response = await axios.get(
        `${API_URL}/competition?page=${current_page ? current_page : page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setComplist(response.data.data);
      setCurrent_page(response.data.current_page);
      setLinnks(response.data.links);
      const initialCheckedStates = response.data.data.reduce((acc, item) => {
        acc[item.id] = item.status === 1;
        return acc;
      }, {});
      setCheckedStates(initialCheckedStates);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  // show popup form
  const showPopup = () => {
    setisPopupVisible(!isPopupVisible);
    setErrors({});
    setComp({
      competition: "",
      competition_description: "",
    });
    setImageInput("");
    setFile(null);
  };
  // toggle dropdown
  const toggleDropdown = (id) => {
    setVisibleDropdown(id);
  };
  useEffect(() => {
    fetchData(current_page);
  }, [current_page]);

  // form validation
  const validateFormData = () => {
    let errors = {};

    if (!comp.competition?.trim()) {
      errors.competition = "Category is required";
    }
    if (!comp.competition_description?.trim()) {
      errors.competition_description = "Description is required";
    }
    if (!file) {
      errors.imageInput = "Image is required";
    }
    if(!imageError){
      return
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };
  const imageChange = (e) => { 
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024;

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: "Only JPG, JPEG, and PNG files are allowed." });
        setImageError(false)
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: "File size should not exceed 2 MB." });
        setImageError(false)
        return;
      }
      setFile(selectedFile);
      setImageInput(URL.createObjectURL(selectedFile));
      setComp({ ...comp, imageInput: selectedFile.name });
      setImageError(true)
    }
  };

  // submit form
  const handleCompetition = (e) => { 
    e.preventDefault();
    if (validateFormData()) {
      const Form = new FormData();
      Form.append("data", JSON.stringify(comp));
      Form.append("imageInput", file);
      Form.append("status", comp.status || false);
      try {
        axios
          .post(`${API_URL}/addCompetition`, Form, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((response) => {
            e.target.reset();
            toast.success(response.data.message);
            setisPopupVisible(!isPopupVisible);
            fetchData();
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              setErrors(error.response.data.error);
            } else {
              console.error("Error:", error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // get form data when edit
  const editCompetition = async (id) => {
    try {
      const response = await axios.get(
        `${API_URL}/editComptition/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      var res_comp_name = response.data.data.name;
      var res_comp_desc = response.data.data.com_desc;
      var res_comp_image = response.data.data.image;
      var res_comp_status = response.data.data.status;
      var res_comp_id = response.data.data.id;
      // console.log(res_comp_image);
      setComp((values) => ({
        ...values,
        competition: res_comp_name,
        competition_description: res_comp_desc,
        status: res_comp_status,
        id: res_comp_id,
      }));
      setFile(res_comp_image);
      setImageInput(`${BACKEND_URL}/storage/images/${res_comp_image}`);
      setisPopupVisible(!isPopupVisible);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  // update data
  const handleEditCompetition = async (e) => {
    e.preventDefault();
    const updateId = comp.id;
    if (validateFormData()) {
      const Form = new FormData();
      Form.append("data", JSON.stringify(comp));
      Form.append("imageInput", file);
      try {
        const response = await axios.post(
          `${API_URL}/editSubmitComptition/${updateId}`,
          Form,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        e.target.reset();
        toast.success(response.data.message);
        fetchData();
        setisPopupVisible(!isPopupVisible);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }
  };
  // delete data
  const deleteBanner = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .post(
              `${API_URL}/deleteCompetition/${id}`,
              { id: id },
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              }
            )
            .then((response) => {
              fetchData();
              Swal.fire(
                "Deleted!",
                "Your category has been deleted.",
                "success"
              );
            })
            .catch((error) => {
              if (error.response && error.response.data.error) {
                setErrors(error.response.data.error);
              } else {
                console.error("Error:", error);
              }
            });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    });
  };

  const handleCheck = async (id) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    try {
      const response = await axios.post(
        `${API_URL}/competition/changeStatus/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const pagination = (url) => {
    if (url) {
      const page = new URL(url).searchParams.get('page');
      setCurrent_page(Number(page));
    }
  }
  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div
                className="content-wrapper"
                style={{ display: "inline-block" }}
              >
                {/* <!-- Content --> */}
                <ToastContainer />
                {/* <!-- Content --> */}
                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">
                            Category List
                          </span>
                        </h4>
                        <button
                          type="button"
                          className="btn btn-primary rounded-pill"
                          onClick={showPopup}
                        >
                          {" "}
                          <i className="ti ti-plus ti-xs"></i>Add Category
                        </button>
                      </div>
                      <div className="row">
                        <div className="card">
                          {/* <h5 className="card-header">Hoverable rows</h5> */}
                          <div className="table-responsive text-nowrap">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Sr. no</th>
                                  <th>Name</th>
                                  <th>Description</th>
                                  <th>image</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {complist.map &&
                                  complist.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>{(current_page * 10) + index + 1 - 10}</td>
                                      <td>
                                        <span className="fw-medium">
                                          {item.name}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fw-medium">
                                          {truncateString(item.com_desc, 50)}
                                        </span>
                                      </td>
                                      <td>
                                        <img
                                          src={`${BACKEND_URL}/storage/images/${item.image}`}
                                          height="50px"
                                          width="50px"
                                        />
                                      </td>
                                      <td>
                                        <label class="switch">
                                          <input
                                            type="checkbox"
                                            className="switch-input"
                                            checked={
                                              checkedStates[item.id] || false
                                            }
                                            onChange={() =>
                                              handleCheck(item.id)
                                            }
                                            name="status"
                                          />{" "}
                                          <span class="switch-toggle-slider">
                                            <span class="switch-on"></span>
                                            <span class="switch-off"></span>
                                          </span>
                                          <span class="switch-label">
                                            {checkedStates[item.id]
                                              ? "Active"
                                              : " Deactive"}
                                          </span>
                                        </label>
                                      </td>
                                      <td>
                                        <div
                                          className=""
                                          style={{ marginRight: 50 + "px" }}
                                        >
                                          {/* <button
                                            ref={dropdownRef}
                                            type="button"
                                            id={item.id}
                                            className={`btn p-0 dropdown-toggle hide-arrow ${
                                              visibleDropdown === item.id
                                                ? "show"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              toggleDropdown(item.id)
                                            }
                                          >
                                            <i className="ti ti-dots-vertical"></i>
                                          </button> */}
                                          <div
                                            // className={`dropdown-menu ${
                                            //   visibleDropdown === item.id
                                            //     ? "show"
                                            //     : "show"
                                            // }`}
                                            className="d-flex"
                                          >
                                            <a
                                              className=""
                                              style={{
                                                marginRight: 5 + "px"
                                              }}
                                              onClick={() =>
                                                editCompetition(item.id)
                                              }
                                            >
                                              <i className="ti ti-pencil me-1" style={{
                                                color: "white",
                                                borderRadius: "7.5" + "px",
                                                background: "darkkhaki"
                                              }}></i>{" "}
                                            </a>
                                            <a
                                              className=""
                                              onClick={() =>
                                                deleteBanner(item.id)
                                              }
                                            >
                                              <i className="ti ti-trash me-1" style={{
                                                color: "white",
                                                borderRadius: "7.5" + "px",
                                                background: "#c21027"
                                              }}></i>{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                            style={{
                              marginTop: "17px",
                              textAlign: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <nav aria-label="Page navigation">
                            <ul className="pagination">
                              {links && links.map((item, index) => {
                                let label;
                                if (index === 0) {
                                  label = "previous";
                                } else if (index === links.length - 1) {
                                  label = "next";
                                } else {
                                  label = index;
                                }
                                
                                return (
                                  <li key={index} className={`page-item ${item.active ? 'active' : ''}`}>
                                    <a
                                      className="page-link waves-effect"
                                      onClick={() => pagination(item.url)}
                                    >
                                      {label}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                            </nav>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}
                <div
                  className={`modal fade ${isPopupVisible ? "show" : ""}`}
                  id="addNewCCModal"
                  tabIndex="-1"
                  aria-hidden="true"
                  style={{ display: isPopupVisible ? "block" : "none" }}
                >
                  <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
                    <div className="modal-content p-3 p-md-5">
                      <div className="modal-body">
                        <div className="text-center mb-4">
                          <h3 className="mb-2">
                            {" "}
                            {comp.id ? "Edit Category" : "Add Category"}
                          </h3>
                          {/* <p className="text-muted">Add new card to complete payment</p> */}
                        </div>
                        <form
                          id="addNewCCForm"
                          className="row g-3"
                          method="post"
                          onSubmit={
                            comp.id ? handleEditCompetition : handleCompetition
                          }
                        >
                          <button
                            type="reset"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={showPopup}
                          ></button>
                          <div className="col-12">
                            <label
                              className="form-label w-100"
                              htmlFor="question"
                            >
                              Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group input-group-merge">
                              <input
                                id="competition"
                                name="competition"
                                className="form-control credit-card-mask"
                                type="text"
                                maxLength="50"
                                value={comp.competition}
                                rows={1}
                                onChange={handleComp}
                                placeholder="Enter Category name"
                              />
                              <input type="hidden" value={comp.id} name="id" />
                              <span
                                className="input-group-text cursor-pointer p-1"
                                id="modalAddCard2"
                              >
                                <span className="card-type"></span>
                              </span>
                            </div>
                            {errors.competition && (
                              <span style={{ color: "red" }}>
                                {errors.competition}
                              </span>
                            )}
                            {!errors.competition && (
                              <span style={{ color: "#858585" }}>
                                {"max length 50 caracters"}
                              </span>
                            )}
                          </div>
                          <div className="col-12">
                            <label
                              className="form-label w-100"
                              htmlFor="question"
                            >
                              Description<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group input-group-merge">
                              <textarea
                                id="competition_description"
                                name="competition_description"
                                className="form-control credit-card-mask"
                                type="text"
                                maxLength="500"
                                value={comp.competition_description}
                                rows={3}
                                onChange={handleComp}
                                placeholder="Enter Description"
                              />

                              <span
                                className="input-group-text cursor-pointer p-1"
                                id="modalAddCard2"
                              >
                                <span className="card-type"></span>
                              </span>
                            </div>
                            {errors.competition_description && (
                              <span style={{ color: "red" }}>
                                {errors.competition_description}
                              </span>
                            )}
                            {!errors.competition_description && (
                              <span style={{ color: "#858585" }}>
                                {"max length 500 caracters"}
                              </span>
                            )}
                          </div>
                          <div className="col-12">
                            <label htmlFor="formFile" className="form-label">
                              Upload Image
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="image"
                              onChange={imageChange}
                            />
                            {errors.imageInput && (
                              <div className="error text-danger">
                                {errors.imageInput}
                              </div>
                            )}
                            {imageInput && (
                              <img
                                className="form-control"
                                src={imageInput}
                                alt="Selected"
                                height="70px"
                                style={{
                                  width: "70px",
                                  marginTop: "20px",
                                }}
                              />
                            )}
                          </div>
                          <div className="text-muted">
                            Allowed JPG, JPEG or PNG. Max size of 2mb
                          </div>
                          <label className="form-label">
                            <span style={{ marginRight: 10 + "px" }}>
                              Status
                            </span>
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="switch-input"
                                name="status"
                                checked={comp.status}
                                onChange={handleStatusChange}
                              />
                              <span className="switch-toggle-slider">
                                <span className="switch-on"></span>
                                <span className="switch-off"></span>
                              </span>
                            </label>
                          </label>

                          <div className="col-12 text-center">
                            <Button
                              text={comp.id ? "Update" : "Add"}
                              className="btn btn-primary me-sm-3 me-1"
                            />
                            <button
                              type="reset"
                              className="btn btn-label-secondary btn-reset"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={showPopup}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}
                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page --> */}
          </div>
          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>
          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}
      </div>
    </>
  );
}
