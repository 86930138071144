import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "./Elements/Button";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "./Constants";
import { BACKEND_URL } from "./Constantsurl";


export default function EditBanner() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState({});
  const [banner, setBanner] = useState([]);
  const [file, setFile] = useState(null);
  const [imageError, setImageError] = useState(true);
  const authToken = localStorage.getItem("token");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const imageChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024;

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: "Only JPG, JPEG, and PNG files are allowed." });
        setImageError(false)
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: "File size should not exceed 2 MB." });
        setImageError(false)
        return;
      }
      setFile(selectedFile);
      setBanner(URL.createObjectURL(selectedFile));
      setFormData({ ...formData, imageInput: selectedFile.name });
      setImageError(true)
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/editBanner/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setBanner(`${BACKEND_URL}/storage/images/${response.data.image}`);
      setFormData(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const validateFormData = () => {
    let errors = {};

    if (!formData.heading?.trim()) {
      errors.heading = "Heading is required";
    }

    if (!formData.description?.trim()) {
      errors.description = "Description is required";
    }
    if(!imageError){
      return
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData() && !errors.imageInput) {
      const allData = {
        formData,
      };
      const newFormData = new FormData();
      newFormData.append("allData", JSON.stringify(allData));
      if (file) {
        newFormData.append("imageInput", file);
      }

      try {
        axios
          .post(`${API_URL}/editBanner/${id}`, newFormData, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            navigate("/admin/banner-list");
            toast.success(response.data.message);
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              setErrors(error.response.data.error);
            } else {
              console.error("Error:", error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar  ">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div
                className="content-wrapper"
                style={{ display: "inline-block" }}
              >
                {/* <!-- Content --> */}
                <ToastContainer />

                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="">
                          <span className=" fw-light text-dark">
                            Banner Details
                          </span>
                          <h6 className="text-muted">Fill Banner Details</h6>
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit} method="post">
                        <div className="row">
                          <div className="col-12">
                            <label htmlFor="formFile" class="form-label">
                              Upload Banner<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="image"
                              onChange={imageChange}
                            />
                              <div className="text-muted">
                            Allowed JPG, JPEG or PNG. Max size of 2mb
                          </div>
                            {errors.imageInput && (
                              <div className="error text-danger">
                                {errors.imageInput}
                              </div>
                            )}
                            <img
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="image"
                              src={banner}
                              height="150px"
                              style={{
                                width: "180px",
                                marginTop: "20px",
                              }}
                            />
                          </div>
                          <div className="col-12 mb-4">
                            <label className="form-label">Heading<span style={{ color: "red" }}>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              maxlength="200"
                              name="heading"
                              value={formData.heading}
                              onChange={handleChange}
                              placeholder="Enter heading"
                            />
                            {errors.heading && (
                              <div className="error text-danger">
                                {errors.heading}
                              </div>
                            )}
                               {!errors.heading && (
                              <span style={{ color: "#858585" }}>
                                {"max length 200 caracters"}
                              </span>
                            )}
                          </div>

                          <div className="col-12">
                            <label class="form-label">Description<span style={{ color: "red" }}>*</span></label>
                            <input
                              class="form-control"
                              type="text"
                              maxLength="500"
                              placeholder="Enter description"
                              name="description"
                              value={formData.description}
                              onChange={handleChange}
                            />
                            {errors.description && (
                              <div className="error text-danger">
                                {errors.description}
                              </div>
                            )}
                            {!errors.heading && (
                              <span style={{ color: "#858585" }}>
                                {"max length 500 caracters"}
                              </span>
                            )}
                          </div>
                        </div>
                        <Button
                          text="Update"
                          className="btn btn-primary me-sm-3 me-1"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}

                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}

                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page -->/ */}
          </div>

          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>

          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}
      </div>
    </>
  );
}
