import React from "react";
import Button from "./Elements/Button";
import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./Constants";
import { BACKEND_URL } from "./Constantsurl";

export default function CompanySetting() {
  const authToken = localStorage.getItem("token");

  const initialState = {
    title: "",
    address: "",
    helpCenter: "",
    whatsappNumber: "",
    email: "",
    facebookUrl: "",
    twitterUrl: "",
    instagramUrl: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [buttonText, setButtonText] = useState("Upload new photo");
  const [file, setFile] = useState("../../assets/img/avatars/14.png");
  const [fetchedLogo, setFetchedLogo] = useState('');
  const [errors, setErrors] = useState({});
  const [imageError, setImageError] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateFormData = () => {
    let errors = {};

    if (!formData.title?.trim()) {
      errors.title = "Title is required";
    }
    if (!formData.address?.trim()) {
      errors.address = "Address is required";
    }
    if (!formData.helpCenter?.trim()) {
      errors.helpCenter = "Help center is required";
    }
    if (!formData.whatsappNumber?.trim()) {
      errors.whatsappNumber = "Whatsapp Number is required";
    }
    if (!formData.email?.trim()) {
      errors.email = "Email Support is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
  //  if (!/^https?:\/\/.+/.test(formData.facebookUrl)) {
  //     errors.facebookUrl = "Facebook URL is invalid";
  //   }
  //    if (!/^https?:\/\/.+/.test(formData.twitterUrl)) {
  //     errors.twitterUrl = "Twitter URL is invalid";
  //   }
  //  if (!/^https?:\/\/.+/.test(formData.instagramUrl)) {
  //     errors.instagramUrl = "Instagram URL is invalid";
  //   }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormData()) {
      try {
        const response = await axios.post(
          `${API_URL}/updateCompanySetting`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setFormData(formData);
        toast.success(response.data.message);
      } catch (error) {
        if (error.response && error.response.data.errors) {
          setErrors(error.response.data.errors);
        } else {
          console.error("Error:", error);
        }
      }
    }
  };

  function imageUpload(e) {
    const file = e.target.files[0];
    setFile(URL.createObjectURL(file));
    setButtonText("Uploading...");
  
    const formData = new FormData();
    formData.append("file", file);
  
    const authToken = localStorage.getItem("token");
  
    axios
      .post(`${API_URL}/uploadLogo`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setTimeout(() => {
          setButtonText("Uploaded");
        }, 2000);
        setErrors({});
        setTimeout(() => {
          setButtonText("Upload new photo");
          toast.success(response.data.message);
        }, 4000);
      })
      .catch((error) => {
        let errors = {};
        if (error.response && error.response.data && error.response.data.errors) {
          const errorMessage = error.response.data.errors.file[0];
          errors.file = errorMessage;
          setErrors(errors);
          setTimeout(() => {
            setButtonText("Upload new photo");
          }, 2000);
          setFile(`${BACKEND_URL}/storage/images/${fetchedLogo}`);
        } else {
          console.error("Error:", error);
        }
      });
  }
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/showLogo`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const logo = response.data.logo;
        setFile(
          logo
            ? `${BACKEND_URL}/storage/images/${logo}`
            : "../../assets/img/avatars/14.png"
        );
        setFetchedLogo(logo);
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/ShowComapanySetting`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setFormData({
          title: response.data.data.title,
          address: response.data.data.address,
          helpCenter: response.data.data.help_center,
          whatsappNumber: response.data.data.whatspp_num,
          email: response.data.data.email_support,
          facebookUrl: response.data.data.facebook,
          twitterUrl: response.data.data.twitter,
          instagramUrl: response.data.data.instagram,
        });
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="layout-wrapper layout-content-navbar  ">
        <div className="layout-container">
          {/* <!-- Layout container --> */}
          <div className="layout-page">
            {/* <!-- Content wrapper --> */}
            <div
              className="content-wrapper"
              style={{ display: "inline-block" }}
            >
              {/* <!-- Content --> */}
              <ToastContainer />
              <div className="col-12 px-4 mt-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="container-xxl flex-grow-1 container-p-y">
                      <h4 className="">
                        <span className=" fw-light text-dark">
                          Company Setting Details
                        </span>
                        <h6 className="text-muted">
                          Fill Company Setting Details
                        </h6>
                      </h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {/* <!-- Full Editor --> */}
                        <div className="card mb-4">
                          {/* <!-- Account --> */}
                          <div className="row">
                            <div className="col-lg-6">
                              <h5 className="card-header">Upload Logo<span style={{ color: "red" }}>*</span></h5>

                              <div className="card-body">
                                <div className="d-flex align-items-start align-items-sm-center gap-4">
                                <div>
                                <div>
                                 <img
                                    src={file}
                                    alt="user-avatar"
                                    className="d-block w-px-100 h-px-100 rounded"
                                    id="uploadedAvatar"
                                  />
                                 </div>
                                  <div>
                                  {errors.file && (
                                        <div className="error text-danger">
                                          {errors.file}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                  <div className="button-wrapper">
                                    <label
                                      htmlFor="upload"
                                      className="btn btn-primary me-2 mb-3"
                                      tabIndex="0"
                                    >
                                      <span className="d-none d-sm-block">
                                        {buttonText}
                                      </span>

                                      <i className="ti ti-upload d-block d-sm-none"></i>

                                      <input
                                        onChange={imageUpload}
                                        type="file"
                                        id="upload"
                                        name="file"
                                        className="account-file-input"
                                        hidden
                                      />
                                    </label>

                                    <div className="text-muted">
                                      Allowed JPG, GIF or PNG. Max size of 2mb
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div class="col-xl-6">
                              <div class="mb-4">
                                <div class="card-body">
                                  <div class="mb-3 row">
                                    <div class="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        Company title<span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        onChange={handleChange}
                                        name="title"
                                        placeholder="Company title"
                                        value={formData.title}
                                        id="html5-text-input"
                                      />
                                      {errors.title && (
                                        <div className="error text-danger">
                                          {errors.title}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <div class="col-md-12">
                                      <label
                                        class="form-label"
                                      >
                                        Address<span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        placeholder="Address"
                                        id="html5-search-input"
                                      />
                                      {errors.address && (
                                        <div className="error text-danger">
                                          {errors.address}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <div class="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        Help center<span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        onChange={handleChange}
                                        name="helpCenter"
                                        value={formData.helpCenter}
                                        placeholder="Help center"
                                        id="html5-email-input"
                                      />
                                       {errors.helpCenter && (
                              <div className="error text-danger">
                                {errors.helpCenter}
                              </div>
                            )}
                                    </div>
                                  </div>

                                  <div class="mb-3 row">
                                    <div class="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        Whatsapp Number<span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        onChange={handleChange}
                                        name="whatsappNumber"
                                        value={formData.whatsappNumber}
                                        placeholder="Whatsapp Number"
                                        id="html5-number-input"
                                      />
                                       {errors.whatsappNumber && (
                              <div className="error text-danger">
                                {errors.whatsappNumber}
                              </div>
                            )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div class="mb-4">
                                <div class="card-body">
                                  <div class="mb-3 row">
                                    <div class="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        Email Support<span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        class="form-control"
                                        type="email"
                                        onChange={handleChange}
                                        name="email"
                                        value={formData.email}
                                        id="html5-text-input"
                                      />
                                             {errors.email && (
                              <div className="error text-danger">
                                {errors.email}
                              </div>
                            )}
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <div class="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        Facebook URL
                                      </label>
                                      <input
                                        class="form-control"
                                        type="url"
                                        onChange={handleChange}
                                        name="facebookUrl"
                                        value={formData.facebookUrl}
                                        id="html5-url-input"
                                      />
                                        {errors.facebookUrl && (
                              <div className="error text-danger">
                                {errors.facebookUrl}
                              </div>
                            )}
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <div class="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        Twitter URL
                                      </label>
                                      <input
                                        class="form-control"
                                        type="url"
                                        onChange={handleChange}
                                        name="twitterUrl"
                                        value={formData.twitterUrl}
                                        id="html5-url-input"
                                      />
                                                {errors.twitterUrl && (
                              <div className="error text-danger">
                                {errors.twitterUrl}
                              </div>
                            )}
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <div class="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        Instrgram URL
                                      </label>
                                      <input
                                        class="form-control"
                                        type="url"
                                        onChange={handleChange}
                                        name="instagramUrl"
                                        value={formData.instagramUrl}
                                        id="html5-url-input"
                                      />
                                                          {errors.instagramUrl && (
                              <div className="error text-danger">
                                {errors.instagramUrl}
                              </div>
                            )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- /Account --> */}
                        </div>

                        {/* <!-- /Full Editor --> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <Button
                            text="Update"
                            className="btn btn-primary me-sm-3 me-1"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <!-- / Content --> */}

              {/* <!-- Footer --> */}
              {/* <!-- / Footer --> */}

              <div className="content-backdrop fade"></div>
            </div>
            {/* <!-- Content wrapper --> */}
          </div>
          {/* <!-- / Layout page -->/ */}
        </div>

        {/* <!-- Overlay --> */}
        <div className="layout-overlay layout-menu-toggle"></div>

        {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
        <div className="drag-target"></div>
      </div>
    </>
  );
}
