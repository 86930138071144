import React, { useState, useEffect } from "react";
import axios from "axios"; // Assuming you are using axios for API requests
import "../Dashboard.css";
import { API_URL } from "./Admin/Constants";
const Dashboard = () => {
  // State variables to hold the data
  const [dashboardData, setDashboardData] = useState({
    blogs: 0,
    categories: 0,
    competition: 0,
    data: 0,
    queries: 0,
    user_info: {
      total: 0,
      active: 0,
      inactive: 0,
      new_signups: 0,
    },
  });

  // Fetch data from the Dashboard API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/dashboard-data`);
        setDashboardData(response.data);
      } catch (error) {
        console.error("Error fetching dashboard data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="row g-6 mb-6 mb-5">
        <div className="col-lg-12">
          <h4>Dashboard</h4>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2">Blogs</span>
                  <span className="h3 font-bold mb-0">{dashboardData.blogs}</span>
                </div>
                <div className="col-auto">
                  <div className="badge rounded-circle bg-label-info p-2">
                    <i className="ti ti-pencil ti-sm"></i>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-0 text-sm">
                <span className="badge badge-pill bg-soft-success text-success me-2">
                  <i className="bi bi-arrow-up me-1"></i>12%
                </span>
                <span className="text-nowrap text-xs text-muted">Since last month</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2">Categories</span>
                  <span className="h3 font-bold mb-0">{dashboardData.categories}</span>
                </div>
                <div className="col-auto">
                  <div className="badge rounded-circle bg-label-warning p-2">
                    <i className="ti ti-tag ti-sm"></i>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-0 text-sm">
                <span className="badge badge-pill bg-soft-success text-success me-2">
                  <i className="bi bi-arrow-up me-1"></i>60%
                </span>
                <span className="text-nowrap text-xs text-muted">Since last month</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2">Competition</span>
                  <span className="h3 font-bold mb-0">{dashboardData.competition}</span>
                </div>
                <div className="col-auto">
                  <div className="badge rounded-circle bg-label-danger p-2">
                    <i className="ti ti-trophy ti-sm"></i>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-0 text-sm">
                <span className="badge badge-pill bg-soft-danger text-danger me-2">
                  <i className="bi bi-arrow-down me-1"></i>60%
                </span>
                <span className="text-nowrap text-xs text-muted">Since last month</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-3 col-sm-6 col-12">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <span className="h6 font-semibold text-muted text-sm d-block mb-2">Data</span>
                  <span className="h3 font-bold mb-0">{dashboardData.data}</span>
                </div>
                <div className="col-auto">
                  <div className="badge rounded-circle bg-label-primary p-2">
                    <i className="ti ti-database ti-sm"></i>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-0 text-sm">
                <span className="badge badge-pill bg-soft-success text-success me-2">
                  <i className="bi bi-arrow-up me-1"></i>10%
                </span>
                <span className="text-nowrap text-xs text-muted">Since last month</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="row mb-4">
        {/* Query Overview */}
        {/* <div className="col-lg-6 col-sm-6 mb-4">
          <div className="card shadow-sm rounded">
            <div className="card-header bg-light">
              <h4 className="card-title mb-1">$42.5k</h4>
              <div className="d-flex justify-content-between">
                <small className="d-block mb-1 text-muted">Query Overview</small>
                <p className="card-text text-success">+18.2%</p>
              </div>
            </div>
            <div className="card-body">
              <div className="row mt-3">
                <div className="col-4">
                  <div className="d-flex gap-2 align-items-center mb-2">
                    <span className="badge bg-label-info p-1 rounded-circle">
                      <i className="ti ti-shopping-cart ti-xs"></i>
                    </span>
                    <p className="mb-0">Queries</p>
                  </div>
                  <h5 className="mb-0 pt-1 text-nowrap">{dashboardData.queries}</h5>
                  <small className="text-muted">6,440</small>
                </div>
                <div className="col-4">
                  <div className="divider divider-vertical">
                    <div className="divider-text">
                      <span className="badge-divider-bg bg-label-secondary">VS</span>
                    </div>
                  </div>
                </div>
                <div className="col-4 text-end">
                  <div className="d-flex gap-2 justify-content-end align-items-center mb-2">
                    <span className="badge bg-label-primary p-1 rounded-circle">
                      <i className="ti ti-link ti-xs"></i>
                    </span>
                    <p className="mb-0">Responses</p>
                  </div>
                  <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">25.5%</h5>
                  <small className="text-muted">12,749</small>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div className="progress w-100" style={{ height: "8px" }}>
                  <div
                    className="progress-bar bg-info"
                    style={{ width: "70%" }}
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "30%" }}
                    aria-valuenow="30"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Support Tracker */}
        <div className="col-md-6 mb-4">
          <div className="card shadow-sm rounded h-100">
            {/* <div className="card-header d-flex justify-content-between bg-light">
              <div className="card-title mb-0">
                <h4 className="mb-0">Support Tracker</h4>
                <small className="text-muted">Last 7 Days</small>
              </div>
              <div className="dropdown">
                <button
                  className="btn p-0"
                  type="button"
                  id="supportTrackerMenu"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical ti-sm text-muted"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="supportTrackerMenu"
                >
                  <a className="dropdown-item">View More</a>
                  <a className="dropdown-item">Delete</a>
                </div>
              </div>
            </div> */}
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-sm-4 col-md-12 col-lg-6">
                  <div className="mt-lg-4 mt-lg-2 mb-lg-12 mb-2 pt-1">
                    <h4 className="mb-0">User Information</h4>
                    <p className="mb-0">Total Users: {dashboardData.user_info.total}</p>
                  </div>
                  <ul className="list-unstyled p-0 m-0">
                    <li className="d-flex gap-3 align-items-center mb-lg-3 pt-2 pb-1">
                      <div className="badge rounded-circle bg-label-primary p-2">
                        <i className="ti ti-user ti-sm"></i>
                      </div>
                      <div>
                        <h6 className="mb-0 text-nowrap">Active Users</h6>
                        <small className="text-muted">{dashboardData.user_info.active}</small>
                      </div>
                    </li>
                    <li className="d-flex gap-3 align-items-center mb-lg-3 pb-1">
                      <div className="badge rounded-circle bg-label-info p-2">
                        <i className="ti ti-user-check ti-sm"></i>
                      </div>
                      <div>
                        <h6 className="mb-0 text-nowrap">Inactive Users</h6>
                        <small className="text-muted">{dashboardData.user_info.inactive}</small>
                      </div>
                    </li>
                    <li className="d-flex gap-3 align-items-center pb-1">
                      <div className="badge rounded-circle bg-label-warning p-2">
                        <i className="ti ti-user-check ti-sm"></i>
                      </div>
                      <div>
                        <h6 className="mb-0 text-nowrap">New Sign-Ups</h6>
                        <small className="text-muted">{dashboardData.user_info.new_signups}</small>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-8 col-md-12 col-lg-8">
                  <div id="supportTracker"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
