import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Alert from '@mui/material/Alert';
import { API_URL } from "./Admin/Constants";

export default function Register() {
  const initialState = {
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    is_admin: 1,  // Add hidden field here
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.username.trim()) {
      errors.username = "Name is required";
    } else if (formData.username.length < 3) {
      errors.username = "Name must be at least 3 characters long";
    } else if (formData.username.length > 50) {
      errors.username = "Name must be less than 50 characters long";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.username)) {
      errors.username = "Name can only contain letters and spaces";
    } else if (/^\s|\s$/.test(formData.username)) {
      errors.username = "Name cannot start or end with whitespace";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (!formData.confirm_password) {
      errors.confirm_password = "Confirm Password is required";
    } else if (formData.confirm_password !== formData.password) {
      errors.confirm_password = "Confirm Password mismatch";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${API_URL}/register`,
          formData
        );
        setFormData(initialState);
        setShowAlert(response.data.message);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      } catch (error) {
        if (error.response && error.response.data.error) {
          setErrors(error.response.data.error);
        } else {
          console.error("Error:", error);
        }
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className="authentication-wrapper authentication-cover authentication-bg">
        <div className="authentication-inner row">
          <div className="d-none d-lg-flex col-lg-7 p-0">
            {/* Other code remains unchanged */}
          </div>
          <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
            <div className="w-px-400 mx-auto">
              <div className="app-brand mb-4">
                <img src="/assets/images/logo1.png" alt="Logo" className="img-fluid" />
              </div>
              <h3 className="mb-1">Adventure starts here 🚀</h3>
              <p className="mb-4">Make your app management easy and fun!</p>
              {showAlert && (
                <Alert severity="success" color="success">
                  {showAlert}
                </Alert>
              )}
              <form
                id="formAuthentication"
                className="mb-3"
                action="#"
                method="post"
                onSubmit={handleSubmit}
              >
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    placeholder="Enter your username"
                    autoFocus
                  />
                  {errors.username && (
                    <div className="error text-danger">{errors.username}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                  />
                  {errors.email && (
                    <div className="error text-danger">{errors.email}</div>
                  )}
                </div>
                <div className="mb-3 form-password-toggle">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="form-control"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                    />
                    <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}>
                      <i className={showPassword ? "ti ti-eye" : "ti ti-eye-off"}></i>
                    </span>
                  </div>
                  {errors.password && (
                    <div className="error text-danger">{errors.password}</div>
                  )}
                </div>
                <div className="mb-3 form-password-toggle">
                  <label className="form-label" htmlFor="confirm_password">
                    Confirm Password
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm_password"
                      className="form-control"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleChange}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="confirm_password"
                    />
                    <span className="input-group-text cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
                      <i className={showConfirmPassword ? "ti ti-eye" : "ti ti-eye-off"}></i>
                    </span>
                  </div>
                  {errors.confirm_password && (
                    <div className="error text-danger">{errors.confirm_password}</div>
                  )}
                </div>
                {/* Add a hidden input for is_admin */}
                <input type="hidden" name="is_admin" value={formData.is_admin} />
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="terms-conditions"
                      name="terms"
                    />
                    <label className="form-check-label" htmlFor="terms-conditions">
                      I agree to
                      <a href="#">privacy policy & terms</a>
                    </label>
                  </div>
                </div>
                <button className="btn btn-primary d-grid w-100">Sign up</button>
              </form>
              <p className="text-center">
                <span>Already have an account?</span>
                <Link to="/login">
                  <span>Sign in instead</span>
                </Link>
              </p>
              <div className="divider my-4">
                <div className="divider-text">or</div>
              </div>
              {/* Other social buttons and elements */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
