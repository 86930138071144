import React, { useState, useEffect } from "react";
import Button from "./Elements/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { API_URL } from "./Constants";

export default function SmtpSetting() {
  const authToken = localStorage.getItem("token");

  const initialState = {
    email: "",
    password: "",
    host: "",
    port: "",
  };

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false); // Loading state

  const validateFormData = () => {
    let errors = {};

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!formData.password.trim()) {
      errors.password = "Password is required";
    }

    if (!formData.host.trim()) {
      errors.host = "Host is required";
    }

    if (!formData.port.trim()) {
      errors.port = "Port is required";
    } else if (isNaN(formData.port)) {
      errors.port = "Port must be a number";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/ShowSmtpSetting`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setFormData({
          email: response.data.data.email,
          password: response.data.data.password,
          host: response.data.data.host,
          port: response.data.data.port,
        });
      } catch (err) {
        console.error("Error fetching data", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormData()) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${API_URL}/updateSmtpSetting`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setFormData(formData);
        toast.success(response.data.message);
      } catch (error) {
        if (error.response && error.response.data.error) {
          setErrors(error.response.data.error);
        } else {
          console.error("Error:", error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <div className="layout-page">
            <div className={`${loading ? "blur" : ""}`}>
              <ToastContainer />
              <div className="col-12 px-4 mt-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="container-xxl flex-grow-1 container-p-y">
                      <h4 className="">
                        <span className="fw-light text-dark">
                          Email SMTP Details
                        </span>
                        <h6 className="text-muted">Fill Email SMTP Details</h6>
                      </h4>
                    </div>
                    <div className="row">
                      <form onSubmit={handleSubmit}>
                        <div className="card mb-4">
                          <div className="row">
                            <div className="col-xl-6">
                              <div className="mb-4">
                                <div className="card-body">
                                  <div className="mb-3 row">
                                    <div className="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Email Address
                                      </label>
                                      <input
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        maxLength="100"
                                        onChange={handleChange}
                                        value={formData.email}
                                        placeholder="Email Address"
                                        id="html5-text-input"
                                      />
                                      {errors.email && (
                                        <span style={{ color: "red" }}>
                                          {errors.email}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Password
                                      </label>
                                      <input
                                        className="form-control"
                                        type="password"
                                        onChange={handleChange}
                                        value={formData.password}
                                        name="password"
                                        maxLength="255"
                                        placeholder="password"
                                        id="html5-search-input"
                                      />
                                      {errors.password && (
                                        <span style={{ color: "red" }}>
                                          {errors.password}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="mb-4">
                                <div className="card-body">
                                  <div className="mb-3 row">
                                    <div className="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Host
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Host"
                                        maxLength="255"
                                        onChange={handleChange}
                                        value={formData.host}
                                        name="host"
                                        id="html5-text-input"
                                      />
                                      {errors.host && (
                                        <span style={{ color: "red" }}>
                                          {errors.host}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-12">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Port
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        onChange={handleChange}
                                        value={formData.port}
                                        name="port"
                                        maxLength="20"
                                        placeholder="Port"
                                        id="html5-search-input"
                                      />
                                      {errors.port && (
                                        <span style={{ color: "red" }}>
                                          {errors.port}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Button
                            text="Update"
                            className="btn btn-primary me-sm-3 me-1"
                            style={{ width: "100px" }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
      </div>
      {loading && (
        <div className="loading-spinner">
          <ClipLoader color={"#123abc"} loading={loading} size={50} />
        </div>
      )}
      <style jsx>{`
        .blur {
          filter: blur(5px);
          pointer-events: none;
        }
        .loading-spinner {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 9999;
        }
      `}</style>
    </>
  );
}
