import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "./Admin/Elements/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Footer from "./Admin/Layout/Footer";
import { API_URL } from "./Admin/Constants";
export default function AddEvents() {
  const initialState = {
    heading: "",
    link: "", 
  };
  const authToken = localStorage.getItem("token");
  const [quillInput, setQuillInput] = useState({ description: "" });
  const [formData, setFormData] = useState(initialState);
  const [imageInput, setImageInput] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [imageError, setImageError] = useState(true);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleStatusChange = (e) => {
    setFormData({ ...formData, status: e.target.checked });
  };
  const handleQuillChange = (value) => {
    setQuillInput({ ...quillInput, description: value });
  };
  const imageChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const maxSize = 2 * 1024 * 1024;

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: 'Only JPG, JPEG, and PNG files are allowed.' });
        setImageError(false)
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: 'File size should not exceed 2 MB.' });
        setImageError(false)
        return; 
      }
    setFile(selectedFile);
    setImageInput(URL.createObjectURL(selectedFile));
    setFormData({ ...formData, imageInput: selectedFile.name });
    setImageError(true)
    }
  };

  const validateFormData = () => {
    let errors = {};

    if (!formData.date?.trim()) {
      errors.date = "Date is required";
    }
    if (!formData.imageInput?.trim()) {
      errors.imageInput = "Image is required";
    }
    if (!formData.heading?.trim()) {
      errors.heading = "Heading is required";
    }
    if (!quillInput.description?.trim()) {
      errors.description = "Description is required";
    }
    if (formData.link && !/^https?:\/\/[^\s$.?#].[^\s]*$/i.test(formData.link)) {
      errors.link = "Please enter a valid URL";
    }
    if(!imageError){
      return
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData()) {
        const allData = {
            formData,
            quillInput,
          };
      const newFormData = new FormData();
      newFormData.append("allData", JSON.stringify(allData));
      newFormData.append("status", formData.status || false);

      if (imageInput) {
        newFormData.append("imageInput", file);
      }
      try {
        axios
          .post(
            `${API_URL}/addEvents`,
            newFormData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log('API Response:', response.data); // Check if the API response is correct
            toast.success(response.data.message);  // Show success message
            setTimeout(() => {
              navigate("/admin/events", { state: { message: response.data.message } });
          }, 1000); // 1000ms delay (1 second)
      })
          .catch((error) => {
            if (error.response && error.response.data.errors) {
              setErrors(error.response.data.errors);
            } else {
              console.error(error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div
                className="content-wrapper"
                style={{ display: "inline-block" }}
              >
                {/* <!-- Content --> */}
                <ToastContainer />

                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">
                            Events
                          </span>
                          <h6 className="text-muted">
                            Fill Events
                          </h6>
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit} method="post">
                        <div className="row">
                        <div className="col-12 mb-4">
                            <label className="form-label">Heading<span style={{ color: "red" }}>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="100"
                              name="heading"
                              value={formData.heading}
                              onChange={handleChange}
                              placeholder="Enter heading"
                            />
                            {errors.heading && (
                              <div className="error text-danger">
                                {errors.heading}
                              </div>
                            )}
                             {!errors.heading && (
                              <span style={{ color: "#858585" }}>
                                {"max length 100 caracters"}
                              </span>
                            )}
                          </div>
                          <div className="col-6 mb-4">
                            <label className="form-label">Choose Date<span style={{ color: "red" }}>*</span></label>
                            <input
                              className="form-control"
                              type="date"
                              name="date"
                              onChange={handleChange}
                            />
                            {errors.date && (
                              <div className="error text-danger">
                                {errors.date}
                              </div>
                            )}
                          </div>
                          <div className="col-6">
                            <label htmlFor="formFile" className="form-label">
                              Upload Image<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="image"
                              onChange={imageChange}
                            />
                             <div className="text-muted">
                            Allowed JPG, JPEG or PNG. Max size of 2mb
                          </div>
                            {errors.imageInput && (
                              <div className="error text-danger">
                                {errors.imageInput}
                              </div>
                            )}
                            {file && (
                              <img
                                className="form-control"
                                src={imageInput}
                                alt="Selected"
                                height="150px"
                                style={{
                                  width: "180px",
                                  marginTop: "20px",
                                }}
                              />
                            )}
                          </div>
                          <div className="col-12 mb-4">
                          <label className="form-label">
                              Event Link<span style={{ color: "red" }}>*</span>
                            </label>
  <input
    type="text"
    className="form-control"
    maxLength="255"
    name="link"
    value={formData.link}
    onChange={handleChange}
    placeholder="Enter link"
  />
  {errors.link && (
    <div className="error text-danger">
      {errors.link}
    </div>
  )}
  {!errors.link && (
    <span style={{ color: "#858585" }}>
      {"Max length 255 characters"}
    </span>
  )}
</div>
                    
                          <div className="col-12">
                          <label htmlFor="formFile" className="form-label">
                              Description<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="card">
                              <ReactQuill
                                theme="snow"
                                value={quillInput.description}
                                onChange={handleQuillChange}
                                style={{ minHeight: "300px" }}
                                name="description"
                              />
                          
                            </div>
                            {errors.description && (
                              <div className="error text-danger">
                                {errors.description}
                              </div>
                            )}
                          </div>
                          <div className="col-12 m-4">
                          <label className="form-label">
                            <span style={{ marginRight: 10 + "px" }}>
                              Status
                            </span>
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="switch-input"
                                name="status"
                                checked={formData.status}
                                onChange={handleStatusChange}
                              />
                              <span className="switch-toggle-slider">
                                <span className="switch-on"></span>
                                <span className="switch-off"></span>
                              </span>
                            </label>
                          </label>
                          </div>
                        </div>
                        <Button
                          text="Submit"
                          className="btn btn-primary me-sm-3 me-1"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}

                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}
                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page -->/ */}
          </div>
          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>
          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}
      </div>
    </>
  );
}
