import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "./Elements/Button";
import Footer from "./Layout/Footer";
import Swal from "sweetalert2";
import { API_URL } from "./Constants";

export default function Faq() {
  const dropdownRef = useRef(null);

  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [isPopupVisible, setisPopupVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [current_page, setCurrent_page] = useState("1");
  const [links, setLinnks] = useState([]);
  const [checkedStates, setCheckedStates] = useState({});
  const [faqs, setFaqs] = useState({
    question: "",
    answer: "",
  });
  const authToken = localStorage.getItem("token");
  const [faq, setFaq] = useState({
    question: "",
    answer: "",
  });

  const closeOpenMenus = (e) => {
    if (visibleDropdown && !dropdownRef.current?.contains(e.target)) {
      setTimeout(() => {
        setVisibleDropdown(null);
      }, 300);
    }
  };
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [visibleDropdown]);

  const toggleDropdown = (id) => {
    setVisibleDropdown(id);
  };


  const addFaq = (e) => {
    setFaq({
      ...faq,
      [e.target.name]: e.target.value,
    });
  };

  const showPopup = () => {
    setisPopupVisible(!isPopupVisible);
    setErrors({});
    setFaq({
      question: "",
      answer: "",
      category: "",
    });
  };
  const validateFormData = () => {
    let errors = {};

    if (!faq.category?.trim()) {
      errors.category = "Category is required";
    } 

    if (!faq.question?.trim()) {
      errors.question = "Question is required";
    }

    if (!faq.answer?.trim()) {
      errors.answer = "Answer is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const editFaq = async (id) => {
    try {
      const response = await axios.get(
        `${API_URL}/editFaqs/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      var res_question = response.data.data.question;
      var res_answer = response.data.data.answer;
      var res_id = response.data.data.id;

      setFaq((values) => ({
        ...values,
        question: res_question,
        answer: res_answer,
        id: res_id,
      }));

      setisPopupVisible(!isPopupVisible);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const deleteFaq = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
    try {
      axios
        .post(
          `${API_URL}/deletefaq/${id}`,
          { id: id },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.data);
          fetchData();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setErrors(error.response.data);
          } else {
            console.error("Error:", error);
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }
});
  };
  const updateHandleFaq = async (e) => {
    e.preventDefault();
    const faqId = faq.id;
    if (validateFormData()) {
      try {
        const response = await axios.post(
          `${API_URL}/editSubmitFaq/${faqId}`,
          faq,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        toast.success(response.data.message);
        // setFaqs([...faqs, response.data.faq]);
        setisPopupVisible(!isPopupVisible);
        fetchData();
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }
  };
  const handleFaq = async (e) => {
    e.preventDefault();
    if (validateFormData()) {
      const question = faq.question.trim();
      const answer = faq.answer.trim();
      const data = { question, answer };

      try {
        const response = await axios.post(
          `${API_URL}/postFaqs`,
          data,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        toast.success(response.data.message);
        setisPopupVisible(!isPopupVisible);
        fetchData();
      } catch (error) {
        if (error.response && error.response.data.error) {
          setErrors(error.response.data.error);
        } else {
          console.error("Error:", error);
        }
      }
    }
  };
  const fetchData = async (page) => {
    try {
      const response = await axios.get(
        `${API_URL}/getFaqs?page=${current_page ? current_page : page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFaqs(response.data.faq.data);
      setCurrent_page(response.data.faq.current_page);
      setLinnks(response.data.faq.links);
      const initialCheckedStates = response.data.faq.data.reduce((acc, item) => {
        acc[item.id] = item.status == 1;
        return acc;
      }, {});
      setCheckedStates(initialCheckedStates);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const handleCheck = async (id) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    try {
      const response = await axios.post(
        `${API_URL}/faq/changeStatus/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData(current_page);
  }, [current_page]);
  const pagination = (url) => {
    if (url) {
      const page = new URL(url).searchParams.get('page');
      setCurrent_page(Number(page));
    }
  }
  return (
    <>
      <div>
        {/* <!-- Layout wrapper --> */}
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            {/* <!-- Layout container --> */}
            <div className="layout-page">
              {/* <!-- Content wrapper --> */}
              <div
                className="content-wrapper"
                style={{ display: "inline-block" }}
              >
                {/* <!-- Content --> */}
                <ToastContainer />
                {/* <!-- Content --> */}
                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">FAQ List</span>
                        </h4>
                        <button
                          onClick={showPopup}
                          type="button"
                          className="btn btn-primary rounded-pill"
                        >
                          {" "}
                          <i className="ti ti-plus ti-xs"></i>Add Faqs
                        </button>
                      </div>
                      <div className="row">
                        <div className="card">
                          {/* <h5 className="card-header">Hoverable rows</h5> */}
                          <div className="table-responsive text-nowrap">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Sr. no</th>
                                  <th>Question</th>
                                  <th>Answer</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody className="table-border-bottom-0">
                                {faqs.map &&
                                  faqs.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>{(current_page * 10) + index + 1 - 10}</td>
                                      <td>
                                        <span className="fw-medium">
                                          {truncateString(item.question, 20)}
                                        </span>
                                      </td>
                                      <td>{truncateString(item.answer, 20)}</td>
                                      <td>
                                        <label class="switch">
                                          <input
                                            type="checkbox"
                                            className="switch-input"
                                            checked={checkedStates[item.id] || false}
                                            onChange={() => handleCheck(item.id)}
                                            name="status"
                                          />{" "}
                                          <span class="switch-toggle-slider">
                                            <span class="switch-on"></span>
                                            <span class="switch-off"></span>
                                          </span>
                                          <span class="switch-label">
                                          {checkedStates[item.id] ? "Active" : " Deactive"}
                                          </span>
                                        </label>
                                      </td>
                                      <td>
                                        <div      className=""
                                          style={{ marginRight: 50 + "px" }}>
                                          <div
                                           className="d-flex"
                                          >
                                            <a
                                              style={{
                                                marginRight: 5 + "px"
                                              }}
                                              onClick={() => editFaq(item.id)}
                                            >
                                              <i className="ti ti-pencil me-1" style={{
                                                color: "white",
                                                borderRadius: "7.5" + "px",
                                                background: "darkkhaki"
                                              }}></i>{" "}
                                            </a>
                                            <a
                                              onClick={() => deleteFaq(item.id)}
                                            >
                                              <i className="ti ti-trash me-1" style={{
                                                color: "white",
                                                borderRadius: "7.5" + "px",
                                                background: "#c21027"
                                              }}></i>{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          <div
                            style={{
                              marginTop: "17px",
                              textAlign: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <nav aria-label="Page navigation">
                            <ul className="pagination">
                              {links && links.map((item, index) => {
                                let label;
                                if (index === 0) {
                                  label = "previous";
                                } else if (index === links.length - 1) {
                                  label = "next";
                                } else {
                                  label = index;
                                }

                                return (
                                  <li key={index} className={`page-item ${item.active ? 'active' : ''}`}>
                                    <a
                                      className="page-link waves-effect"
                                      onClick={() => pagination(item.url)}
                                    >
                                      {label}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- / Content --> */}

                {/* <!-- Footer --> */}
                {/* <!-- / Footer --> */}
                <div className="content-backdrop fade"></div>
              </div>
              {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page --> */}
          </div>
          {/* <!-- Overlay --> */}
          <div className="layout-overlay layout-menu-toggle"></div>
          {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
          <div className="drag-target"></div>
        </div>
        {/* <!-- / Layout wrapper --> */}
      </div>
      {/* <!-- Add New Credit Card Modal --> */}
      <div
        className={`modal modal_width fade ${isPopupVisible ? "show" : ""}`}
        id="addNewCCModal"
        tabIndex="-1"
        aria-hidden="true"
        style={{ display: isPopupVisible ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={showPopup}
              ></button>
              <div className="text-center mb-4">
                <h3 className="mb-2">{faq.id ? "Update Faqs" : "Add Faqs"}</h3>
                {/* <p className="text-muted">Add new card to complete payment</p> */}
              </div>
              <form
                id="addNewCCForm"
                className="row g-3"
                method="post"
                onSubmit={faq.id ? updateHandleFaq : handleFaq}
              >

<div className="col-12">
  <label className="form-label w-100" htmlFor="category">
    Category<span style={{ color: "red" }}>*</span>
  </label>
  <div className="input-group input-group-merge">
    <select
      id="category"
      name="category"
      onChange={addFaq}
      value={faq.category}
      className="form-control"
      aria-describedby="modalAddCardCategory"
    >
      <option value="">Select Category</option>
      <option value="General Information">General Information</option>
      <option value="Registration and Participation">Registration and Participation</option>
      <option value="Submission Guidelines">Submission Guidelines</option>
    </select>
    <span
      className="input-group-text cursor-pointer p-1"
      id="modalAddCardCategory"
    >
      <span className="card-type"></span>
    </span>
  </div>
  {errors.category && (
    <span style={{ color: "red" }}>{errors.category}</span>
  )}
</div>


                <div className="col-12">
                  <label className="form-label w-100" htmlFor="question">
                    Question<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="input-group input-group-merge">
                    <textarea
                      id="question"
                      name="question"
                      onChange={addFaq}
                      value={faq.question}
                      className="form-control credit-card-mask"
                      type="text"
                      rows={1}
                      placeholder="Enter Question"
                      aria-describedby="modalAddCard2"
                    />

                    <span
                      className="input-group-text cursor-pointer p-1"
                      id="modalAddCard2"
                    >
                      <span className="card-type"></span>
                    </span>
                  </div>
                  {errors.question && (
                    <span style={{ color: "red" }}>{errors.question}</span>
                  )}
                </div>
                <div className="col-12">
                  <label
                    className="form-label w-100"
                  >
                    Answer<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="input-group input-group-merge">
                    <textarea
                      id="answer"
                      name="answer"
                      onChange={addFaq}
                      value={faq.answer}
                      className="form-control credit-card-mask"
                      type="text"
                      placeholder="Enter Answer"
                      aria-describedby="modalAddCard2"
                    />
                    <span
                      className="input-group-text cursor-pointer p-1"
                      id="modalAddCard2"
                    >
                      <span className="card-type"></span>
                    </span>
                  </div>
                  {errors.answer && (
                    <span style={{ color: "red" }}>{errors.answer}</span>
                  )}
                </div>
                <div className="col-12 text-center">
                  <Button
                    text={faq.id ? "Update" : "Add"}
                    className="btn btn-primary me-sm-3 me-1"
                  />
                  <button
                    type="reset"
                    className="btn btn-label-secondary btn-reset"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={showPopup}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!--/ Add New Credit Card Modal --> */}
    </>
  );
}
