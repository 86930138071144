import React, { useState } from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  return (
    <>
      <aside id="layout-menu" className={`layout-menu menu-vertical menu bg-menu-theme `}>
        <div className="app-brand demo">
          <Link to="/" className="app-brand-link">
            <span className="app-brand-logo demo">
              {/* <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                  fill="#7367F0"
                />
                <path
                  opacity="0.06"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                  fill="#161616"
                />
                <path
                  opacity="0.06"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                  fill="#161616"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                  fill="#7367F0"
                />
              </svg> */}
                <img src="/assets/images/logo1.png" alt="Logo" className="img-fluid" /> {/* Use the logo image here */}
            </span>
            <span className="app-brand-text demo menu-text fw-bold">Talentpedia</span>
          </Link>

          <a href="#" className="layout-menu-toggle menu-link text-large ms-auto" onClick={toggleSidebar}>
            <i className={`ti ${isSidebarOpen ? "ti-x" : "ti-menu-2"} ti-sm align-middle`}></i>
          </a>
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <li className="menu-item">
            <Link to="/" className="menu-link">
              <i className="menu-icon tf-icons ti ti-smart-home"></i>
              <div data-i18n="Dashboard">Dashboard</div>
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/admin/competition" className="menu-link">
              <i className="menu-icon tf-icons ti ti-smart-home"></i>
              <div data-i18n="Dashboard">Manage Category</div>
            </Link>
          </li>
          {/* <li className={`menu-item ${openDropdown === "Events" ? "open" : ""}`}>
            <a
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("Events")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Events</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "Events" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/events" className="menu-link">
                  <div data-i18n="Collapsed menu">List</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/add-events" className="menu-link">
                  <div data-i18n="Content navbar">Add</div>
                </Link>
              </li>
            </ul>
          </li> */}
          <li className={`menu-item ${openDropdown === "Blogs" ? "open" : ""}`}>
            <a
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("Blogs")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Inspirational Stories</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "Blogs" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/blog-list" className="menu-link">
                  <div data-i18n="Collapsed menu">List</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/add-blogs" className="menu-link">
                  <div data-i18n="Content navbar">Add</div>
                </Link>
              </li>
            </ul>
          </li>

          <li className={`menu-item ${openDropdown === "Story" ? "open" : ""}`}>
            <a
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("Story")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Story</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "Story" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/story-list" className="menu-link">
                  <div data-i18n="Collapsed menu">List</div>
                </Link>
              </li>
              
            </ul>
          </li>

          <li className={`menu-item ${openDropdown === "Query" ? "open" : ""}`}>
            <a
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("Query")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Query</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "Query" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/query-list" className="menu-link">
                  <div data-i18n="Collapsed menu">List</div>
                </Link>
              </li>
            </ul>
          </li>
          
          <li className={`menu-item ${openDropdown === "User" ? "open" : ""}`}>
            <a
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("User")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">User</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "User" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/user-list" className="menu-link">
                  <div data-i18n="Collapsed menu">List</div>
                </Link>
              </li>
              
            </ul>
          </li>

          <li className={`menu-item ${openDropdown === "competition-details" ? "open" : ""}`}>
            <a
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("competition-details")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Competition Details</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "competition-details" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/competition-details" className="menu-link">
                  <div data-i18n="Collapsed menu">List</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/add-competition-details" className="menu-link">
                  <div data-i18n="Content navbar">Add</div>
                </Link>
              </li>
            </ul>
          </li>
          <li className={`menu-item ${openDropdown === "company-competition" ? "open" : ""}`}>
            <a
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("company-competition")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Company Competition</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "company-competition" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/company-competition" className="menu-link">
                  <div data-i18n="Collapsed menu">List</div>
                </Link>
              </li>
              {/* <li className="menu-item">
                <Link to="/admin/add-company-competition" className="menu-link">
                  <div data-i18n="Content navbar">Add</div>
                </Link>
              </li> */}
            </ul>
          </li>
          <li className={`menu-item ${openDropdown === "banners" ? "open" : ""}`}>
            <a
              href="#"
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("banners")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Manage Banner</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "banners" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/add-banner" className="menu-link">
                  <div data-i18n="Collapsed menu">Add</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/banner-list" className="menu-link">
                  <div data-i18n="Content navbar">List</div>
                </Link>
              </li>
            </ul>
          </li>
          <li className={`menu-item ${openDropdown === "cms" ? "open" : ""}`}>
            <a
              href="#"
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("cms")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Manage CMS</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "cms" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/cms/about-us" className="menu-link">
                  <div data-i18n="Collapsed menu">About Us</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/cms/privacy-policy" className="menu-link">
                  <div data-i18n="Content navbar">Privacy Policy</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/cms/return-policy" className="menu-link">
                  <div data-i18n="Content nav + Sidebar">Return Policy</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/cms/terms-and-conditions" className="menu-link">
                  <div data-i18n="Horizontal">Terms & Conditions</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/cms/faq" className="menu-link">
                  <div data-i18n="Without menu">Faqs</div>
                </Link>
              </li>
            </ul>
          </li>
          <li className={`menu-item ${openDropdown === "settings" ? "open" : ""}`}>
            <a
              className="menu-link menu-toggle"
              onClick={() => toggleDropdown("settings")}
            >
              <i className="menu-icon tf-icons ti ti-layout-sidebar"></i>
              <div data-i18n="Layouts">Manage Setting</div>
            </a>
            <ul className={`menu-sub ${openDropdown === "settings" ? "d-block" : "d-none"}`}>
              <li className="menu-item">
                <Link to="/admin/company-setting" className="menu-link">
                  <div data-i18n="Collapsed menu">Company Setting</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/admin/smtp-setting" className="menu-link">
                  <div data-i18n="Content navbar">Email / SMTP</div>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;
